import React, { forwardRef, useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import "./SlotMachine.css";
import audioFile from "../../assets/spinsound.mp3";
import WheelOfFortune from "../WheelOfFortune/WheelOfFortune";
import {
  getWinningItemsSubject,
  getWinningItemsSubjectForAnimation,
} from "../../api/apiConfig";
import { useItemDetails } from "../../context/ItemDetailsContext";
import { getImagePath } from "../../utils/utility";


const SlotMachine = forwardRef(({ numColumns, box }, ref) => {
  const visibleRows = 3; // Fixed visible rows
  const totalRows = 1000; // Total rows in the animation
  const [slotResults, setSlotResults] = useState([]);
  const [spinning, setSpinning] = useState(false);
  const [winningRows, setWinningRows] = useState([]); // Array to track winning rows for each column
  const columnRefs = useRef([]);
  const rectanglesRef = useRef(null);

  // Get the CSS variable value for --row-height
  const root = document.documentElement; // Reference to the :root element
  const rowHeight = parseInt(
    getComputedStyle(root).getPropertyValue("--row-height")
  );

  const backgroundImageUrl = `${process.env.PUBLIC_URL}/static/slots-background.webp`;
  const slotMachineStyle = {
    backgroundImage: `url(${backgroundImageUrl})`,
    backgroundSize: "cover",
    backgroundPosition: "center center",
    backgroundRepeat: "no-repeat",
    backdropFilter: "blur(10px)", // Apply blur effect

    /* Additional background properties and styles can be added here */
  };

  const [columnColors, setColumnColors] = useState([]);

  //To track which item is winning by using api
  var winningItems;
  getWinningItemsSubject
    // .pipe(throttle(() => interval(1000)))
    .subscribe({
      next: (items) => {
        winningItems = items;
      },
    });

    const { openDetails } = useItemDetails(); // Assuming you have access to the openDetails function from your context

    const handleSetProduct = (item) => {
      openDetails(item);
    };
  

  const getRandomColor = () => {
    // Generate random RGB values
    const r = Math.floor(Math.random() * 256); // Red: 0-255
    const g = Math.floor(Math.random() * 256); // Green: 0-255
    const b = Math.floor(Math.random() * 256); // Blue: 0-255
    const a = 0.2; // Fixed transparency for semi-transparent effect

    return `rgba(${r}, ${g}, ${b}, ${a})`;
  };

  // Ensure columnRefs matches the number of columns
  useEffect(() => {
    columnRefs.current = Array(numColumns).fill(null);
    const randomColors = Array(numColumns)
      .fill()
      .map(() => getRandomColor());
    setColumnColors(randomColors);
  }, [numColumns]);

  // Initialize slot results whenever numColumns or box changes
  useEffect(() => {
    if (box?.items?.length > 0) {
      const newResults = Array(numColumns)
        .fill(0)
        .map(() =>
          Array(totalRows)
            .fill(0)
            .map((_, idx) => idx % box.items.length)
        );
      setSlotResults(newResults);
    }
  }, [numColumns, box]);

  const playAudio = () => {
    const audio = new Audio(audioFile);
    audio.play();
  };

  const triggerClickInWheelOfFortune = () => {
    if (rectanglesRef.current) {
      rectanglesRef.current.handleButtonClick();
    }
  };

  const startSpin = () => {
    if (spinning) return;
    setSpinning(true);

    if (numColumns === 1) {
      triggerClickInWheelOfFortune();
      playAudio();
      setTimeout(() => setSpinning(false), 3000); // Arbitrary spin duration
      return;
    }

    playAudio();

    const itemsPerColumn = box.items.length; // Number of items in a column (e.g., 10)
    const baseRows = 30; // Rows to spin before stopping

    var difference = 0;
    if ((baseRows + 500) % itemsPerColumn > 0) {
      difference = itemsPerColumn - ((baseRows + 500) % itemsPerColumn);
    }

    // const rowHeight = 120; // Height of each row in pixels
    const speed = 0.1; // Time (seconds) per row

    //This is for sequential winning items 1, 2, 3 , ...
    // Calculate the target row offsets for each column
    // const targetOffsets = Array(numColumns)
    //   .fill(0)
    //   .map((_, idx) => baseRows + difference + ((idx ) % itemsPerColumn)); // Stops at 301, 302, 303, etc.

    //This is for random winning items from items
    var targetOffsets = Array(numColumns)
      .fill(0)
      .map(
        (_, idx) =>
          baseRows + difference + Math.floor(Math.random() * itemsPerColumn) + 1
      ); // Stops at 301, 302, 303, etc.

    // Save the winning rows for all columns
    setWinningRows(targetOffsets);

    const TOKEN = localStorage.getItem("token");

    if (TOKEN != null) {
      const winningItemIndexes = winningItems.map((winningItem) =>
        box?.items?.findIndex((item) => item.item_id === winningItem.item_id)
      );
    
      console.log("Winning Item Indexes:", winningItemIndexes);
    
      // Use winning item indexes in place of random calculation
       targetOffsets = winningItemIndexes.map(
        (winningIndex) => baseRows + difference + winningIndex 
      ); // Adjust offset as per your logic
    
      console.log("Target Offsets:", targetOffsets);
    
      // Save the winning rows for all columns
      setWinningRows(targetOffsets);
    }


    // Animate each column
    columnRefs.current.forEach((col, idx) => {
      if (!col) return; // Skip undefined refs

      // Reset the column to its initial position
      col.style.transition = "none"; // Disable transition for instant reset
      col.style.transform = `translateY(0)`; // Reset to the top

      // Allow the reset to take effect before starting the animation
      setTimeout(() => {
        // Calculate the target transform position
        const targetRow = targetOffsets[idx];
        const yAdjustment = rowHeight > 1000 ? 60 : 40;
        const targetY = -(targetRow * rowHeight) - yAdjustment;

        // Calculate the duration based on distance
        const distance = targetRow; // Rows to cover
        const duration = distance * speed; // Duration in seconds

        // Apply the spinning animation
        col.style.transition = `transform ${duration}s ease-out`;
        col.style.transform = `translateY(${targetY}px)`; // Move to the target position

        // Stop spinning after the animation duration
        setTimeout(() => {
          if (idx === numColumns - 1) {
            setSpinning(false); // Stop spinning after the last column finishes
            getWinningItemsSubjectForAnimation.next({ items: winningItems });

          }
        }, duration * 1000); // Convert seconds to milliseconds
      }, 50); // Short delay to allow reset to take effect
    });
  };

  const getImageSource = (item) => getImagePath(item?.detail?.picture) || "";

  return (
    <div className="slot-machine" style={slotMachineStyle}>
      {numColumns === 1 ? (
        <div className="wheelContainer">
          <WheelOfFortune numberOfParts={16} box={box} ref={rectanglesRef} />
          <div className="white-line-vertical"></div>
        </div>
      ) : (
        <div className="slots">
          {slotResults.map((column, colIndex) => (
            <div
              key={colIndex}
              className="slot-column"
              ref={(el) => (columnRefs.current[colIndex] = el)}
              style={{ backgroundColor: columnColors[colIndex % numColumns] }}
            >
              {column.map((itemIndex, rowIndex) => {
                const isWinningRow = winningRows[colIndex] + 500 === rowIndex;

                // Debugging log
                if (isWinningRow) {
                  console.log(
                    `Winning Row for Column ${colIndex}: Row ${rowIndex}`
                  );
                }

                return (
                  <div
                    key={rowIndex}
                    className={`slot-element ${
                      isWinningRow ? "highlight" : ""
                    }`}
                  >
                    <img
                      src={getImageSource(box.items[itemIndex])}
                      // alt={rowIndex}
                      onClick={() =>
                        handleSetProduct(
                          box.items[itemIndex]
                        )
                      }

                      alt={box.items[itemIndex]?.detail?.name || ""}
                    />
                  </div>
                );
              })}
            </div>
          ))}
        </div>
      )}
      <button
        ref={ref}
        style={{ display: "none" }}
        onClick={startSpin}
        disabled={spinning}
      >
        {spinning ? "Spinning..." : "Spin"}
      </button>
    </div>
  );
});

SlotMachine.propTypes = {
  numColumns: PropTypes.number.isRequired,
  box: PropTypes.object.isRequired,
};

export default SlotMachine;
