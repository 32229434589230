import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { RootState, AppDispatch } from "../app/store";
import { GiftBox } from "../utils/boxTypes";

// Define the slice state interface
interface ReceivedGiftBoxesState {
  recievedGiftBoxes: GiftBox[];
  isLoading: boolean;
  currentPage: number;
  boxesPerPage: number;
  totalItems: number;
  selectedGiftBox: GiftBox | null;
}

const initialState: ReceivedGiftBoxesState = {
  recievedGiftBoxes: [],
  isLoading: false,
  currentPage: 1,
  boxesPerPage: 20,
  totalItems: 0,
  selectedGiftBox: null,
};

// Async thunk for fetching received gift boxes
export const fetchReceivedGiftBoxes = createAsyncThunk<
  { list: GiftBox[]; total: number }, // Return type
  void, // Argument type
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: string;
  }
>(
  "receivedGiftBoxes/fetchReceivedGiftBoxes",
  async (_, { getState, rejectWithValue }) => {
    const state = getState();
    const { currentPage, boxesPerPage } = state.receivedGiftBoxes;

    const offset = (currentPage - 1) * boxesPerPage;

    try {
      const response = await axios.get(
        `https://demo1-api.boxchaos.com/api/gifts/received`,
        {
          params: { offset, limit: boxesPerPage },
        }
      );
      return {
        list: response.data.list,
        total: response.data.total,
      };
    } catch (error: any) {
      console.error("Error fetching received gift boxes:", error);
      return rejectWithValue("Failed to fetch received gift boxes");
    }
  }
);

// Create the slice
const receivedGiftBoxesSlice = createSlice({
  name: "receivedGiftBoxes",
  initialState,
  reducers: {
    setCurrentPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
    setBoxesPerPage: (state, action: PayloadAction<number>) => {
      state.boxesPerPage = action.payload;
    },
    setSelectedGiftBox: (state, action: PayloadAction<GiftBox | null>) => {
      state.selectedGiftBox = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchReceivedGiftBoxes.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchReceivedGiftBoxes.fulfilled, (state, action) => {
        const { list, total } = action.payload;
        state.recievedGiftBoxes = list;
        // If selectedGiftBox exists, update it with the new one from the fetched list
        if (state.selectedGiftBox) {
          const updatedGiftBox = list.find(
            (box) => box.id === state.selectedGiftBox?.id
          );
          if (updatedGiftBox) {
            state.selectedGiftBox = updatedGiftBox;
          }
        }
        state.totalItems = total;
        state.isLoading = false;
      })
      .addCase(fetchReceivedGiftBoxes.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

// Export actions and reducer
export const { setCurrentPage, setBoxesPerPage, setSelectedGiftBox } =
  receivedGiftBoxesSlice.actions;
export default receivedGiftBoxesSlice.reducer;
