import React from "react";
import styled from "styled-components";
import HoverableBox from "./HoverableBox";
import { BoxVideo, Item } from "../utils/boxTypes";
import { getImageSource, getVideoPath } from "../utils/utility";
import MediaDisplayForWinningItem from "./MediaDisplayForWinningItem";

const DialogueWrapper = styled.div<{ itemCount: number }>`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #222;
  color: #fff;
  border-radius: 12px;
  width: ${({ itemCount }) =>
    itemCount <= 3 ? "400px" : `calc(min(${itemCount * 150 + (itemCount - 1) * 20}px, 90%))`}; /* Dynamic width */
  max-width: 90%;
  padding: 20px 15px;
  z-index: 1000;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  color: #fff;
  font-size: 1.5rem;
  cursor: pointer;

  &:hover {
    color: #ff0077;
  }
`;

const ItemsGrid = styled.div<{ itemCount: number }>`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr)); /* Auto-fit for dynamic layout */
  gap: 20px;
  margin: 0 auto;
  margin-top: 15px;
  justify-content: center;

  @media (max-width: 768px) {
    grid-template-columns: repeat(3, 1fr); /* 3 items per row for smaller devices */
    justify-content: center;
    align-items: center;
  }

  @media (max-width: 480px) {
    grid-template-columns: repeat(3, 1fr); /* Still 3 per row but wraps to the next row */
  }
`;

const ItemCard = styled.div`
  display: flex;
  flex-direction: column; /* Vertical stacking */
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #fff;
  border-radius: 10px;
  width: 100%;
  max-width: 300px; /* Increased max width for better appearance */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Slightly larger shadow */
  gap: 15px; /* Space between stacked items */
`;

const ItemImageWrapper = styled.div`
  width: 100%; /* Larger size */
  height: 100%;
  margin: 0 auto;

  @media (max-width: 768px) {
    width: 200px; /* Adjust for smaller screens */
    height: 200px;
  }

  @media (max-width: 480px) {
    width: 150px; /* Further adjust for very small screens */
    height: 150px;
  }
`;

const ItemDetails = styled.div`
  margin-top: 10px;
  color: #333;
`;

const ItemName = styled.p`
  font-size: 0.9rem;
  font-weight: bold;
  margin: 5px 0;
  text-align: center;
  word-wrap: break-word;
  white-space: normal;
  line-height: 1.2rem;
  max-height: 3.6rem;
  overflow: hidden;
`;

const ItemPrice = styled.p`
  font-size: 0.9rem;
  color: #ff0077;
  margin: 5px 0;
`;

const OkButton = styled.button`
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #ff0077;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  cursor: pointer;
  display: block;
  margin-left: auto;
  margin-right: auto;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #ff3399;
  }
`;




const BoxWinningDialogue: React.FC<{
  winningItems: Item[] | null;
  boxVideos: BoxVideo[] | null;
  onClose: () => void;
}> = ({ winningItems, boxVideos, onClose }) => {
  if (!winningItems) return null;

  const itemCount = winningItems.length;

  const getGridItem = (item: Item, index: number, boxVideos: BoxVideo[] | null) => {
    const videoFile = boxVideos?.find((video) => video.item_id === item.item_id)?.file || null;
    const imageSource = getImageSource(item.detail?.picture);
    const videoSource = videoFile ? getVideoPath(videoFile) : null;

    return (
      <ItemCard key={index}>
            <ItemImageWrapper>
          <MediaDisplayForWinningItem
            selectedBox={{
              picture: imageSource,
              video: videoSource,
            }}
          />
        </ItemImageWrapper>
        <ItemDetails>
          <ItemPrice>${item.detail?.price || "N/A"}</ItemPrice>
          <ItemName>{item.detail?.name || "Unknown Item"}</ItemName>
        </ItemDetails>
      </ItemCard>
    );
  };

  return (
    <DialogueWrapper itemCount={itemCount}>
      <CloseButton onClick={onClose}>×</CloseButton>
      <h2 style={{ textAlign: "center", fontSize: "1.5rem", margin: "10px 0" }}>
        You Won Items
      </h2>
      <ItemsGrid itemCount={itemCount}>
        {winningItems.map((item, index) => getGridItem(item, index, boxVideos))}
      </ItemsGrid>
      <OkButton onClick={onClose}>OK</OkButton>
    </DialogueWrapper>
  );
};

export default BoxWinningDialogue;
