import React, { useEffect } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { RootState, AppDispatch } from "../app/store";
import { fetchSentGiftBoxes, setCurrentPage } from "../features/sentBoxesSlice";
import ReactPaginate from "react-paginate";
import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai";

const Container = styled.div`
  width: 100%;
  background-color: #000d0d;
  border: 1px solid #004d4d;
  padding: 1rem;
  color: #d9ffff;
`;

const Table = styled.div`
  width: 100%;
  display: table;
  border-collapse: collapse;
`;

const Cell = styled.td<{ align?: string; width?: string }>`
  padding: 0.5rem;
  text-align: ${({ align }) => align || "left"};
  width: ${({ width }) => width || "auto"};
      font-family: 'Corbel', sans-serif; /* Use Corbel font */

`;

const HeadingCell = styled.th<{ align?: string; width?: string }>`
  padding: 0.5rem;
  text-align: ${({ align }) => align || "left"};
  width: ${({ width }) => width || "auto"};
  color: #ccfffb;
      font-family: 'Corbel', sans-serif; /* Use Corbel font */

`;

const Row = styled.tr`
  border-bottom: 1px solid #004d4d;
  &:last-child {
    border-bottom: none;
  }
`;

const HeadingRow = styled.tr`
  background-color: #004d4d;
  font-weight: bold;
`;



const StatusIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ColorBox = styled.div<{ background: string }>`
  background-color: ${({ background }) => background};
  padding: 0.2rem 0.5rem;
  border-radius: 3px;
  text-align: center;
  font-size: 0.875rem;
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1rem;
`;

const SectionTitle = styled.h2`
  color: #fff;
  font-size: 1.5rem;
    font-family: 'Corbel', sans-serif; /* Use Corbel font */

`;

const SentGiftsComponent: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const { sentGiftBoxes, isLoading, currentPage, boxesPerPage, totalItems } = useSelector(
    (state: RootState) => state.sentGiftBoxesSlice
  );

  useEffect(() => {
    dispatch(fetchSentGiftBoxes());
  }, [dispatch, currentPage]);

  const handlePageChange = (selectedPage: number) => {
    dispatch(setCurrentPage(selectedPage));
  };

  const getColorForClass = (className: string): string => {
    switch (className.toLowerCase()) {
      case "bronze":
        return "#cd7f32";
      case "silver":
        return "#c0c0c0";
      case "gold":
        return "#ffd700";
      case "platinum":
        return "#e5e4e2";
      case "diamond":
        return "#b9f2ff";
      default:
        return "#808080";
    }
  };

  return (
    <Container>
      <SectionTitle>History of Sent Gifts</SectionTitle>
      <Table>
        <HeadingRow>
          <HeadingCell width="5%">SN.</HeadingCell>
          <HeadingCell width="20%">Sent To</HeadingCell>
          <HeadingCell width="15%">Class</HeadingCell>
          <HeadingCell width="35%">Box Title</HeadingCell>
          <HeadingCell width="15%" align="right">Price</HeadingCell>
          <HeadingCell width="10%">Status</HeadingCell>
        </HeadingRow>

        {isLoading ? (
          <tr>
            <Cell colSpan={6} align="center">Loading...</Cell>
          </tr>
        ) : sentGiftBoxes.length === 0 ? (
          <tr>
            <Cell colSpan={6} align="center">No data found.</Cell>
          </tr>
        ) : (
          sentGiftBoxes.map((box, index) => (
            <Row key={index}>
              <Cell>{(currentPage - 1) * boxesPerPage + index + 1}</Cell>
              <Cell>{box.user_details.email}</Cell>
              <Cell>
                <ColorBox background={getColorForClass(box.gift_class)}>
                  {box.gift_class}
                </ColorBox>
              </Cell>
              <Cell>{box.box_details.name}</Cell>
              <Cell align="right">${box.box_details.final_value.toFixed(2)}</Cell>
              <Cell align="center">
                <StatusIcon>
                  {box.opened ? <AiOutlineCheck size={20} /> : <AiOutlineClose size={20} />}
                </StatusIcon>
              </Cell>
            </Row>
          ))
        )}



      </Table>
      <PaginationWrapper>
        <ReactPaginate
          pageCount={Math.ceil(totalItems / boxesPerPage)}
          pageRangeDisplayed={5}
          marginPagesDisplayed={2}
          onPageChange={({ selected }) => handlePageChange(selected + 1)}
          previousLabel={"Previous"}
          nextLabel={"Next"}
          breakLabel={"..."}
          containerClassName={"pagination"}
          pageClassName={"page-item"}
          pageLinkClassName={"page-link"}
          previousClassName={"page-item"}
          previousLinkClassName={"page-link"}
          nextClassName={"page-item"}
          nextLinkClassName={"page-link"}
          breakClassName={"page-item"}
          breakLinkClassName={"page-link"}
          activeClassName={"active"}
        />
      </PaginationWrapper>
    </Container>
  );
};

export default SentGiftsComponent;
