import React, { useEffect, useRef, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled, { keyframes } from "styled-components";
import { RootState, AppDispatch } from "../app/store";
import { getImagePath } from "../utils/utility";
import { fetchReceivedGiftBoxes, setCurrentPage, setSelectedGiftBox } from "../features/receivedBoxesSlice";
import BackButton from "./BackButton";
import Footer from "./Footer";
import { setBoxDetails } from "../features/boxDetailsSlice";
import { useNavigate } from "react-router-dom";
import { GiftBox } from "../utils/boxTypes";
import ReactPaginate from "react-paginate";
import { ImCheckmark } from "react-icons/im";


const Container = styled.div`
  width: 100%;
  background-color: #000d0d;
  padding: 1rem;
  color: #d9ffff;
  overflow: auto; /* or overflow: scroll */
`;

const ContentWrapper = styled.div`
  padding: 20px;
`;

const SquareGrid = styled.div<{ columns: number }>`
  display: grid;
  grid-template-columns: repeat(${(props) => props.columns}, 1fr);
  gap: 20px;
`;

export const SquareBox = styled.div`
  width: 100%;
  padding-top: 121.4%;
  position: relative;
  border-radius: 16px;
  font-family: SonsieOne;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  background-image: url("/assets/Box_Container_BG.png"); /* Add your image path here */
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  overflow: visible; /* Ensure content doesn't affect the box size */
`;

export const BoxContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px; /* Adjust padding as needed */
  box-sizing: border-box;
`;


export const BoxImage = styled.img<{ $isHovered: boolean }>`
  width: 71.4%;
  object-fit: cover;
  position: absolute;
  top: ${(props) => (props.$isHovered ? "8%" : "14%")};
  left: 50%;
  transform: translateX(-50%);
  transition: top 0.3s ease-in-out; /* Add smooth transition effect */
`;

const LoadingMessage = styled.p`
  text-align: center;
  color: #fff;
`;


const Spacer = styled.div`
  height: 100px;
`;

// New styled components for additional elements
// Define a custom prop interface
interface CheckMarkProps {
  isVisible: boolean;
}

// Apply the custom prop interface to the styled component
const CheckMark = styled.img<CheckMarkProps>`
    position: absolute;
    top: 9%;
    left: 5%;
    width: 15px;
    height: 15px;
    display: ${(props) => (props.isVisible ? "block" : "none")};
  `;


interface BoxTitleProps {
  isOpened: boolean;
}

export const BoxTitle = styled.h3<BoxTitleProps>`
    font-family: 'Corbel', sans-serif; /* Use Corbel font */
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    color: #80d5ff; /* Light blue color as in screenshot */
    background-color: ${(props) => (props.isOpened ? '#333333' : '#3a4000')}; /* Conditional background color */
    padding: 4px 8px; /* Padding around the text */
    border-radius: 4px; /* Rounded edges for the background */
    position: absolute;
    bottom: 5%; /* Position at the bottom */
    left: 50%;
    transform: translateX(-50%); /* Center horizontally */
    margin: 0; /* Remove default margin */
    width: 90%; /* Adjust width if necessary */
  `;




// Styled component for pagination wrapper
const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1rem;

  .pagination {
    display: flex;
    list-style: none;
    padding: 0;
  }

  .page-item {
    margin: 0 5px;
  }

  .page-link {
    padding: 8px 12px;
    border: 1px solid #ccc;
    color: #333;
    text-decoration: none;
    cursor: pointer;
  }

  .active .page-link {
    background-color: #007bff;
    color: white;
    border-color: #007bff;
  }
`;

interface CheckMarkProps {
  isVisible: boolean;
}

// Define a subtle pop-in animation
const popIn = keyframes`
  0% {
    transform: scale(0.8);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`;

const CheckMarkIcon = styled(ImCheckmark) <CheckMarkProps>`
  position: absolute;
  top: 9%;
  left: 5%;
  font-size: 32px; /* Larger size for prominence */
  color: #28a745; /* Vibrant green color */
  background: #f0f8f5; /* Subtle light background to highlight the icon */
  border-radius: 50%; /* Circular background */
  padding: 10px; /* Space around the icon */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Shadow for a floating effect */
  display: ${(props) => (props.isVisible ? "block" : "none")};
  animation: ${popIn} 0.3s ease-in-out; /* Smooth pop-in animation */
`;

const SectionTitle = styled.h2`
  color: #fff;
  font-size: 1.5rem;
    font-family: 'Corbel', sans-serif; /* Use Corbel font */

`;

const ReceivedGiftsComponent: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();

  const containerRef = useRef<HTMLDivElement | null>(null); // Reference for Container
  const [hoveredBox, setHoveredBox] = useState<string | null>(null);

  const { recievedGiftBoxes, isLoading, currentPage, totalItems, boxesPerPage } = useSelector(
    (state: RootState) => state.receivedGiftBoxes
  );
  const [boxCount, setBoxCount] = useState(3);

  useEffect(() => {
    dispatch(fetchReceivedGiftBoxes());
  }, [dispatch, currentPage]);

  useEffect(() => {
    const handleResize = () => {
      const windowWidth = window.innerWidth;
      if (windowWidth < 768) {
        setBoxCount(2);
      } else if (windowWidth < 1024) {
        setBoxCount(3);
      } else {
        setBoxCount(5);
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleBoxClick = (gift: GiftBox) => {
    let box = gift.box_details;
    dispatch(setBoxDetails(box.id));
    dispatch(setSelectedGiftBox(gift));
    navigate(`/gifts/received/${box.id}`);
  };

  const handlePageChange = ({ selected }: { selected: number }) => {
    dispatch(setCurrentPage(selected + 1)); // Update to the new page
  };

  return (
    <Container ref={containerRef}>
      <BackButton />
      <SectionTitle>Received Gifts</SectionTitle>

      <ContentWrapper>
        <SquareGrid columns={boxCount}>
          {recievedGiftBoxes.map((gift) => (
            <SquareBox
                  key={gift.id}
                  onMouseEnter={() => setHoveredBox(gift.id)}
                  onMouseLeave={() => setHoveredBox(null)}
                  onClick={() => handleBoxClick(gift)}
                >
                  <BoxContent>
                    {/* Check Mark if opened */}
                    <CheckMarkIcon isVisible={gift.opened} />
                    {/* Display Lock Icon if locked, otherwise show BoxImage */}
  
                    <BoxImage
                      src={getImagePath(gift.box_details.picture)}
                      $isHovered={hoveredBox === gift.id}
                    />
                    {/* Centered Box Name */}
                    <BoxTitle isOpened={gift.opened || !gift.box_details.is_drawable}>
                      {gift.box_details.name}
                    </BoxTitle>
                  </BoxContent>
                </SquareBox>
          ))}
        </SquareGrid>

        {isLoading && <LoadingMessage>Loading...</LoadingMessage>}
      </ContentWrapper>

      <PaginationWrapper>
        <ReactPaginate
          pageCount={Math.ceil(totalItems / boxesPerPage)}
          pageRangeDisplayed={5}
          marginPagesDisplayed={2}
          onPageChange={handlePageChange}
          previousLabel={"Previous"}
          nextLabel={"Next"}
          breakLabel={"..."}
          containerClassName={"pagination"}
          pageClassName={"page-item"}
          pageLinkClassName={"page-link"}
          previousClassName={"page-item"}
          previousLinkClassName={"page-link"}
          nextClassName={"page-item"}
          nextLinkClassName={"page-link"}
          breakClassName={"page-item"}
          breakLinkClassName={"page-link"}
          activeClassName={"active"}
          forcePage={currentPage - 1}
        />
      </PaginationWrapper>

      <Footer />
      <Spacer />
    </Container>
  );
};

export default ReceivedGiftsComponent;
