import React, { useEffect, useRef } from "react";
import styled, { css } from "styled-components";

interface HoverableBoxProps {
  selectedBox: {
    picture: string;
    video: string | null;
  };
}

const BoxImageColumn = styled.div<{ isHoverable: boolean }>`
  flex: 0 0 23%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  ${(props) =>
    props.isHoverable &&
    css`
      &:hover video {
        display: block;
      }

      &:hover img {
        display: none;
      }
    `}
`;

const BoxImage = styled.img`
  display: block;
  width: 55%;
  height: auto;
  max-width: 100%;
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1; /* Ensure it appears above other elements */

  @media (max-width: 400px) {
    position: relative;
    width: 35%;
    left: 20%;
  }
`;

const VideoWrapper = styled.video`
  display: none;
  width: 85%;
  height: auto;
  max-width: 100%;
  position: absolute;
  top: -20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2; /* Ensure it appears above other elements */

  @media (max-width: 400px) {
    position: relative;
    width: 35%;
    left: 20%;
  }
`;


const HoverableBox: React.FC<HoverableBoxProps> = ({ selectedBox }) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.playbackRate = 0.85; // Slow down video playback
    }
  }, []);

  const localization = {
    box_image: "Box Image", // Hardcoded localization string
  };

  return (
    <BoxImageColumn isHoverable={!!selectedBox.video}>
      <BoxImage
        src={selectedBox.picture}
        alt={localization.box_image}
      />
      {selectedBox.video && (
        <VideoWrapper
          ref={videoRef}
          src={selectedBox.video}
          autoPlay
          muted
          loop
        />
      )}
    </BoxImageColumn>
  );
};

export default HoverableBox;
