import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { RootState } from "../app/store";
import {
  Routes,
  Route,
  useNavigate,
  useLocation,
} from "react-router-dom";
import BoxesPage from "./BoxesPage";
import BoxDetails, { GifBoxDetails } from "./BoxDetails";
import AllBoxesPage, { GiftCategoryBoxesPage } from "./AllBoxes";
import localization from "../services/localization";
import GiftsPage from "./GiftsPage";
import ChaosPage from "./ChaosPage";
import ProfileDetailsLayoutComponent from "./ProfileDetailsLayoutComponent";
import { selectPage } from "../features/pageSlice";
import ResetPasswordComponent from "./RestPassword";
import ActivateAccountComponent from "./ActivateAccount";
import SupportPage from "./SupportPage";
import DocumentLoader from "./DocumentLoader";
import HowItWorksPage from "./HowItWorks";
import VipPage from "./VipPage";
import CryptoOnrampPage from "./CryptoOnrampPage";
import ReceivedGiftsComponent from "./ReceivedGiftsComponent";

const ContentWrapper = styled.div`
  flex: 1;
  padding: 20px;
  background-color: #000c0d;
  color: #755c5c;
  overflow-y: auto;
`;

const ComingSoon = styled.div`
  font-family: Verdana;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  margin: 0 auto; /* Horizontally center the div */
  align-items: center;
`;

const ContentArea: React.FC = () => {
  const selectedPage = useSelector(
    (state: RootState) => state.page.selectedPage
  );
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const TOKEN = localStorage.getItem("token");
    const path = location.pathname;

    if (path.startsWith("/reset-password")) {
      dispatch(selectPage("reset-password"));
      return;
    }

    if (path.startsWith("/activate-account")) {
      dispatch(selectPage("activate-account"));
      return;
    }

    if (!TOKEN && (path.startsWith("/chaos") || path.startsWith("/profile"))) {
      navigate("/"); // Redirect to home page if no token
      return;
    }

    if (selectedPage === "" || selectedPage === "boxes") {
      if (path.startsWith("/gifts")) {
        dispatch(selectPage("gifts"));
      } else if (path.startsWith("/chaos")) {
        dispatch(selectPage("chaos"));
      } else if (path.startsWith("/crypto-onramp")) {
        dispatch(selectPage("crypto-onramp"));
      } else if (path.startsWith("/boxes")) {
        dispatch(selectPage("boxes"));
      } else if (path.startsWith("/profile")) {
        dispatch(selectPage("profile"));
      } else if (path.startsWith("/cookie-policy")) {
        dispatch(selectPage("cookie-policy"));
      } else if (path.startsWith("/privacy-policy")) {
        dispatch(selectPage("privacy-policy"));
      } else if (path.startsWith("/terms-of-service")) {
        dispatch(selectPage("terms-of-service"));
      } else if (path.startsWith("/how-it-works")) {
        dispatch(selectPage("how-it-works"));
      } else if (path.startsWith("/support")) {
        dispatch(selectPage("support"));
      } else {
        dispatch(selectPage("home"));
      }
    }
  }, [selectedPage, location.pathname, dispatch, navigate]);

  return (
    <ContentWrapper>
      {(selectedPage === "boxes" || selectedPage === "home") && (
        <Routes>
          <Route path="/" element={<BoxesPage />} />
          <Route path="/boxes/" element={<AllBoxesPage />} />
          <Route path="/boxes/:boxId" element={<BoxDetails />} />
        </Routes>
      )}
      {selectedPage === "chaos" && (
        <Routes>
          <Route path="/chaos/" element={<ChaosPage />} />
          <Route path="/chaos/:boxId" element={<BoxDetails />} />
        </Routes>
      )}
      {selectedPage === "gifts" && (
        <Routes>
          <Route path="/gifts/" element={<GiftsPage />} />
          <Route path="/gifts/received/" element={<ReceivedGiftsComponent />} />
          <Route path="/gifts/received/:boxId" element={<BoxDetails />} />
          <Route path="/gifts/:categoryId" element={<GiftCategoryBoxesPage />} />
          <Route path="/gifts/:categoryId/:boxId" element={<GifBoxDetails />} />
        </Routes>
      )}
      {selectedPage === "reset-password" && (
        <Routes>
          <Route path="/reset-password/:token" element={<ResetPasswordComponent />} />
        </Routes>
      )}
      {selectedPage === "activate-account" && (
        <Routes>
          <Route path="/activate-account/:token" element={<ActivateAccountComponent />} />
        </Routes>
      )}
      {selectedPage === "cookie-policy" && (
        <Routes>
          <Route path="/cookie-policy" element={<DocumentLoader title="Cookie Policy" docxPath="/assets/docs/cookie_policy.docx" />} />
        </Routes>
      )}
      {selectedPage === "privacy-policy" && (
        <Routes>
          <Route path="/privacy-policy" element={<DocumentLoader title="Privacy Policy" docxPath="/assets/docs/privacy_policy.docx" />} />
        </Routes>
      )}
      {selectedPage === "terms-of-service" && (
        <Routes>
          <Route path="/terms-of-service" element={<DocumentLoader title="Term of service" docxPath="/assets/docs/user_agreement.docx" />} />
        </Routes>
      )}
      {selectedPage === "how-it-works" && (
        <Routes>
          <Route path="/how-it-works" element={<HowItWorksPage />} />
        </Routes>
      )}
      {selectedPage === "support" && (
        <Routes>
          <Route path="/support" element={<SupportPage />} />
        </Routes>
      )}
      {selectedPage === "affiliates" && (
        <ComingSoon>{localization.affiliates_content_coming_soon}</ComingSoon>
      )}
      {selectedPage === "vips" && (
        <Routes>
          <Route path="/vips/" element={<VipPage />} />
          <Route path="/vips/:boxId" element={<BoxDetails />} />
        </Routes>
      )}
      {selectedPage === "profile" && <ProfileDetailsLayoutComponent />}
      {selectedPage === "crypto-onramp" && (  // Add case for the new page
        <Routes>
          <Route path="/crypto-onramp" element={<CryptoOnrampPage />} />
        </Routes>
      )}
      {/* Add cases for other pages */}
    </ContentWrapper>
  );
};

export default ContentArea;
