import React, { useEffect, useRef } from "react";
import styled from "styled-components";

interface MediaDisplayForWinningItemProps {
  selectedBox: {
    picture: string;
    video: string | null;
  };
}

const BoxContainer = styled.div`
flex: 1 1 auto; /* Flexible growth and shrinkage */
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const BoxImage = styled.img`
  display: block;
  width: 100%;
  height: auto;
  max-width: 100%;
  top: 10px;
  left: 50%;

  @media (max-width: 400px) {
    position: relative;
    width: 35%;
    left: 20%;
  }
`;

const VideoWrapper = styled.video`
  display: block;
  width: 110%; /* Increase width beyond its container */
  height: auto; /* Maintain aspect ratio */
  top: -20px;

  @media (max-width: 400px) {
    width: 100%; /* Adjust for smaller screens */
    height: auto; /* Maintain aspect ratio */
    top: 0; /* Remove negative top for better layout */
    left: 0; /* Center the video */
  }
`;


const MediaDisplayForWinningItem: React.FC<MediaDisplayForWinningItemProps> = ({ selectedBox }) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.playbackRate = 0.85; // Slow down video playback
    }
  }, []);

  const localization = {
    box_image: "Box Image", // Hardcoded localization string
  };

  return (
    <BoxContainer>
      {selectedBox.video ? (
        <VideoWrapper
          ref={videoRef}
          src={selectedBox.video}
          autoPlay
          muted
          loop
        />
      ) : (
        <BoxImage
          src={selectedBox.picture}
          alt={localization.box_image}
        />
      )}
    </BoxContainer>
  );
};

export default MediaDisplayForWinningItem;
