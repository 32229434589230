import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { RootState, AppDispatch } from "../app/store";
import { GiftBox } from "../utils/boxTypes";

// Define the slice state interface
interface SentGiftBoxesState {
  sentGiftBoxes: GiftBox[];
  isLoading: boolean;
  currentPage: number;
  boxesPerPage: number;
  totalItems: number;
}

const initialState: SentGiftBoxesState = {
  sentGiftBoxes: [],
  isLoading: false,
  currentPage: 1,
  boxesPerPage: 20,
  totalItems: 0,
};

// Async thunk for fetching received gift boxes
export const fetchSentGiftBoxes = createAsyncThunk<
  { list: GiftBox[]; total: number }, // Return type
  void, // Argument type
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: string;
  }
>(
  "receivedGiftBoxes/fetchSentGiftBoxes",
  async (_, { getState, rejectWithValue }) => {
    const state = getState();
    const { currentPage, boxesPerPage } = state.receivedGiftBoxes;

    const offset = (currentPage - 1) * boxesPerPage;

    try {
      const response = await axios.get(`/gifts/sent`, {
        params: { offset, limit: boxesPerPage },
      });
      return {
        list: response.data.list,
        total: response.data.total,
      };
    } catch (error: any) {
      console.error("Error fetching sent gift boxes:", error);
      return rejectWithValue("Failed to fetch sent gift boxes");
    }
  }
);

// Create the slice
const sentGiftBoxesSlice = createSlice({
  name: "sentGiftBoxesSlice",
  initialState,
  reducers: {
    setCurrentPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
    setBoxesPerPage: (state, action: PayloadAction<number>) => {
      state.boxesPerPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSentGiftBoxes.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchSentGiftBoxes.fulfilled, (state, action) => {
        const { list, total } = action.payload;
        state.sentGiftBoxes = list;
        state.totalItems = total;
        state.isLoading = false;
      })
      .addCase(fetchSentGiftBoxes.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

// Export actions and reducer
export const { setCurrentPage, setBoxesPerPage } = sentGiftBoxesSlice.actions;
export default sentGiftBoxesSlice.reducer;
