import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { RootState } from '../app/store';

// Level spending requirements
const levelSpendingRequirements = [
  0, 1000, 2000, 5000, 10000, 20000, 50000, 100000, 200000, 350000, 500000
];

const LevelContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  background-color: #1a1a1a;
  border-radius: 20px;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;

  @media (min-width: 600px) {
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    padding: 10px;
  }
`;

const LevelRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px 0;
  gap: 8px;
  width: 100%;
  max-width: 1000px;
`;

const LevelLabel = styled.span`
  color: white;
  margin-right: 16px;
  font-family: 'Corbel', sans-serif; /* Use Corbel font */

  @media (max-width: 600px) {
    font-size: 0.9rem;
  }
`;

const LevelCircle = styled.div<{ isActive: boolean; isPast: boolean }>`
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: ${({ isActive, isPast }) =>
    isActive ? '#ff8c42' : isPast ? '#88c0d0' : 'transparent'};
  border: ${({ isActive }) => (isActive ? 'none' : '1px solid #88c0d0')};
  color: ${({ isActive, isPast }) =>
    isActive ? 'black' : isPast ? 'black' : 'white'};
  font-size: 1rem;

  @media (max-width: 600px) {
    width: 24px;
    height: 24px;
    font-size: 0.8rem;
  }
`;

const ProgressBarContainer = styled.div<{ width: number }>`
  width: ${({ width }) => `${width}px`};
  height: 10px;
  background-color: #333;
  border: 1px solid #88c0d0;
  border-radius: 10px;
  position: relative;

  @media (max-width: 600px) {
    width: ${({ width }) => `${width * 0.9}px`};
    height: 8px;
  }
`;

const ProgressFill = styled.div<{ progressPercentage: number }>`
  height: 100%;
  width: ${({ progressPercentage }) => `${progressPercentage}%`};
  background: linear-gradient(to right, #ff8c42, #ffb347);
  border-radius: 10px;
`;

const LevelProgressIndicator: React.FC = () => {
  const vipStatus = useSelector((state: RootState) => state.vip.vipStatus);

  // State and effect for handling window resize
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Check if vipStatus exists before continuing with the logic
  if (!vipStatus) return null;

  const { level, total_spent } = vipStatus;
  const totalLevels = 10;

  const currentLevelRequirement = levelSpendingRequirements[level];
  const nextLevelRequirement = levelSpendingRequirements[level + 1] || currentLevelRequirement;
  const progressPercentage =
    ((total_spent - currentLevelRequirement) / (nextLevelRequirement - currentLevelRequirement)) * 100;

  const circleWidth = isMobile ? 24 : 30;
  const circleMargin = isMobile ? 4 : 8;
  const progressBarMaxWidth = 4 * (circleWidth + circleMargin) - circleMargin;

  return (
    <LevelContainer>
      {isMobile ? (
        <>
          <LevelRow>
            <LevelLabel>Level</LevelLabel>
            {Array.from({ length: level }, (_, index) => (
              <LevelCircle key={index + 1} isActive={false} isPast={true}>
                {index}
              </LevelCircle>
            ))}
            <LevelCircle isActive={true} isPast={false}>
              {level}
            </LevelCircle>
          </LevelRow>

          <LevelRow>
            <ProgressBarContainer width={progressBarMaxWidth}>
              <ProgressFill progressPercentage={Math.min(progressPercentage, 100)} />
            </ProgressBarContainer>
          </LevelRow>

          <LevelRow>
            {Array.from({ length: totalLevels - level }, (_, index) => (
              <LevelCircle key={level + index + 1} isActive={false} isPast={false}>
                {level + index + 1}
              </LevelCircle>
            ))}
          </LevelRow>
        </>
      ) : (
        <LevelRow>
          <LevelLabel>Level</LevelLabel>
          {Array.from({ length: level }, (_, index) => (
            <LevelCircle key={index + 1} isActive={false} isPast={true}>
              {index}
            </LevelCircle>
          ))}
          <LevelCircle isActive={true} isPast={false}>
            {level}
          </LevelCircle>
          <ProgressBarContainer width={progressBarMaxWidth}>
            <ProgressFill progressPercentage={Math.min(progressPercentage, 100)} />
          </ProgressBarContainer>
          {Array.from({ length: totalLevels - level }, (_, index) => (
            <LevelCircle key={level + index + 1} isActive={false} isPast={false}>
              {level + index + 1}
            </LevelCircle>
          ))}
        </LevelRow>
      )}
    </LevelContainer>
  );
};

export default LevelProgressIndicator;
