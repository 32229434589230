// src/components/Sidebar.tsx
import React, { useEffect } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import Feed from "../utils/Feed";
import FeedStyledComponent from "./FeedStyledComponent";
import localization from "../services/localization";
import { fetchLiveFeed } from "../features/liveFeedSlice";
import { AppDispatch } from "../app/store";
import { getImageSource } from "../utils/utility";

const SidebarWrapper = styled.div`
  width: 224px;
  height: 100%; /* Make Sidebar take up 100% height of its parent */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  padding: 16px;
  background-color: #00171a;
  overflow-y: auto; /* Make the sidebar content scrollable */

  @media (max-width: 768px) {
    display: none;
  }
`;

const TextWrapper = styled.div`
  font-family: Verdana;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  color: white;
`;

const SidebarContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Sidebar: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const { feeds, status } = useSelector((state: any) => state.liveFeed);

  useEffect(() => {
    const fetchFeedsPeriodically = () => {
      if (status !== "loading") {
        dispatch(fetchLiveFeed());
      }
    };

    // Initial fetch
    fetchFeedsPeriodically();

    // Set interval for periodic fetching
    const intervalId = setInterval(() => {
      fetchFeedsPeriodically();
    }, 10000);

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, [dispatch]); // Only depends on `dispatch`

  return (
    <SidebarWrapper>
      <TextWrapper>{localization.live_feed}</TextWrapper>
      <SidebarContent>
        {feeds.map((feed: any) => (
          <FeedStyledComponent
            key={feed.id}
            feed={
              new Feed(
                getImageSource(feed.details.picture), // Use details.picture
                "/logo.png", // Static second image
                `$${feed.details.price.toFixed(2)}` // Format price as string
              )
            }
          />
        ))}
      </SidebarContent>
    </SidebarWrapper>
  );
};

export default Sidebar;
