import React, { useState } from 'react';
import styled from 'styled-components';

// Styled Components
const ModalOverlay = styled.div`
  position: fixed;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 50;
  background-color: rgba(31, 41, 55, 0.5); /* Equivalent to bg-gray-800 with opacity-50 */
`;

const ModalContent = styled.div`
  background-color: #00171a;
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 10;
`;

const ModalTitle = styled.h2`
  font-size: 1.125rem;
  font-weight: 600;
  margin-bottom: 16px;
`;

const ModalMessage = styled.p`
  margin-bottom: 24px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
`;

const ConfirmButton = styled.button`
  padding: 8px 16px;
  background-color: #3b82f6; /* Equivalent to bg-blue-500 */
  color: white;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: #2563eb; /* Equivalent to hover:bg-blue-600 */
  }
`;

const CancelButton = styled.button`
  padding: 8px 16px;
  background-color: #e5e7eb; /* Equivalent to bg-gray-300 */
  color: #374151; /* Equivalent to text-gray-700 */
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: #d1d5db; /* Equivalent to hover:bg-gray-400 */
  }
`;

// Update ConfirmFunction to accept React.ReactNode
type ConfirmFunction = (message: React.ReactNode) => Promise<boolean>;

interface UseConfirmationReturn {
  confirm: ConfirmFunction;
  ConfirmationModal: React.FC;
}

// Custom Hook with Confirmation Modal
const useConfirmation = (): UseConfirmationReturn => {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [message, setMessage] = useState<React.ReactNode>(null);
  const [resolveConfirm, setResolveConfirm] = useState<((value: boolean) => void) | null>(null);

  const confirm: ConfirmFunction = (message: React.ReactNode) => {
    return new Promise<boolean>((resolve) => {
      setMessage(message);
      setIsVisible(true);
      setResolveConfirm(() => resolve);
    });
  };

  const handleConfirm = () => {
    if (resolveConfirm) resolveConfirm(true);
    setIsVisible(false);
  };

  const handleCancel = () => {
    if (resolveConfirm) resolveConfirm(false);
    setIsVisible(false);
  };

  const ConfirmationModal: React.FC = () => (
    isVisible ? (
      <ModalOverlay>
        <ModalContent>
          <ModalTitle>Confirm</ModalTitle>
          <ModalMessage>{message}</ModalMessage> {/* Render ReactNode */}
          <ButtonContainer>
            <ConfirmButton onClick={handleConfirm}>Yes</ConfirmButton>
            <CancelButton onClick={handleCancel}>No</CancelButton>
          </ButtonContainer>
        </ModalContent>
      </ModalOverlay>
    ) : null
  );

  return { confirm, ConfirmationModal };
};

export default useConfirmation;
