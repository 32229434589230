import React, { useEffect, useState } from "react";
import "./WheelOfFortune.css";
import PropTypes from "prop-types";
import {
  getWinningItemsSubject, getWinningItemsSubjectForAnimation
} from "../../api/apiConfig";
import { useItemDetails } from "../../context/ItemDetailsContext";
import { getImagePath } from "../../utils/utility";

const WheelOfFortune = React.forwardRef(({ numberOfParts, box }, ref) => {
  const [spinning, setSpinning] = useState(false);
  const { openDetails } = useItemDetails(); // Assuming you have access to the openDetails function from your context

  const handleSetProduct = (item) => {
    openDetails(item);
  };

  //To track which item is winning by using api
  var winningItems;
  getWinningItemsSubject
    // .pipe(throttle(() => interval(1000)))
    .subscribe({
      next: (items) => {
        winningItems = items;
      },
    });

    useEffect(() => {
      const wheel = document.querySelector(".container");
      if (wheel) {
        wheel.style.transform = "rotate(0deg)"; // Set initial transform
      }
    }, []);
    

  useEffect(() => {
    const handleButtonClick = () => {
      // Perform the action when the button is clicked
      resizeElements();
      console.log(
        "Free Spin button clicked in RectanglesAroundCentralPoint.js"
      );
      // You can add your custom action here
      if (!spinning) {
        // Start spinning the wheel
        setSpinning(true);
        const wheel = document.querySelector(".container");
        const TOKEN = localStorage.getItem("token");

        if (TOKEN != null) {
          const numberOfSections = numberOfParts;
          const anglePerSection = (2 * Math.PI) / numberOfSections;
          // const chosenItemIndex = box?.items?.findIndex(item => item.item_id === winningItem.item_id);
          const chosenItemIndex = box?.items?.findIndex(
            (item) => item.item_id === winningItems[0].item_id
          );

          const startAngleOffset = Math.PI / 2; // 90 degrees offset
          // Calculate the current angle of the chosen item
          const currentAngleOfChosenItem = chosenItemIndex * anglePerSection;

          const randomNumber = Math.floor(Math.random() * 6) + 1;

          // Calculate the additional offset to make the chosen item appear as the 0th index item
          const stopAngle =
            0 -
            currentAngleOfChosenItem -
            startAngleOffset +
            randomNumber * 2 * Math.PI;
          // alert(chosenItemIndex +", "+ stopAngle + winningItem.detail.name);
          // wheel.style.transition = "transform ease-out"; // Add CSS transition
          wheel.style.transform = `rotate(${stopAngle}rad)`;
          //TODO: resume here
               // Define the listener function
      const handleTransitionEnd = () => {
        // alert(`Congratulations! You Win! ${winningItem.detail.name}`);
        getWinningItemsSubjectForAnimation.next({ items: winningItems });
        wheel.removeEventListener("transitionend", handleTransitionEnd); // Remove listener
      };

      // Attach the listener
      wheel.addEventListener("transitionend", handleTransitionEnd);
        } else {
          let value = Math.ceil(Math.random() * 3600);
          wheel.style.transform = "rotate(" + value + "deg)";
        }

        // Stop the wheel after 5 seconds (adjust the duration as needed)
        // setTimeout(() => {
        //   setSpinning(false);
        // }, 5000); // 5000 milliseconds = 5 seconds
      }
    };

    // ... Rest of the component code

    if (ref) {
      // Expose the handleButtonClick function to the parent component
      ref.current = { handleButtonClick };
    }

    const getRandomColor = () => {
      const letters = "0123456789ABCDEF";
      let color = "#";
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    };

    function createWheelSectionPathSVG(
      radius,
      numberOfSections,
      additionalRadius
    ) {
      const anglePerSection = (2 * Math.PI) / numberOfSections;

      const pathData = [];
      for (let i = 0; i < numberOfSections; i++) {
        const startAngle = i * anglePerSection - anglePerSection / 2;
        const endAngle = (i + 1) * anglePerSection - anglePerSection / 2;

        const degreeStartAngle = (startAngle * 180) / Math.PI;
        const degreeEndAngle = (endAngle * 180) / Math.PI;

        // alert("Start angle =>" + degreeStartAngle +", endAngle =>" + degreeEndAngle);

        if (degreeStartAngle < 270 && degreeEndAngle > 270) {
          // alert("Start angle =>" + (startAngle*180/Math.PI))
          // console.log("Start angle =>" + (startAngle*180/Math.PI));

          let xAdjustement = additionalRadius / 2;
          let yAdjustment = radius + additionalRadius;
          let x1 = xAdjustement + radius * Math.cos(startAngle);
          let y1 = yAdjustment + radius * Math.sin(startAngle);

          let x2 =
            xAdjustement + (radius + additionalRadius) * Math.cos(startAngle);
          let y2 =
            yAdjustment + (radius + additionalRadius) * Math.sin(startAngle);

          let x3 =
            xAdjustement + (radius + additionalRadius) * Math.cos(endAngle);
          let y3 =
            yAdjustment + (radius + additionalRadius) * Math.sin(endAngle);

          let x4 = xAdjustement + radius * Math.cos(endAngle);
          let y4 = yAdjustment + radius * Math.sin(endAngle);

          // const xAdjustement = x4;
          // x1 = x1 + xAdjustement;
          // x2 = x2 + xAdjustement;
          // x3 = x3 + xAdjustement;
          // y4 = y4 + xAdjustement;

          const arcFlag = anglePerSection < Math.PI ? 0 : 1;

          pathData.push(
            `M ${x1},${y1}`,
            `L ${x2},${y2}`,
            `A ${radius + additionalRadius},${
              radius + additionalRadius
            } 0 ${arcFlag},1 ${x3},${y3}`,
            `L ${x4},${y4}`,
            "Z"
          );
        }
      }

      const wheelPath = pathData.join(" ");

      const pathElement = document.querySelector(".wheel");
      pathElement.setAttribute("d", wheelPath);

      // Get the bounding box of the path
      const bbox = pathElement.getBBox();

      // Set the SVG width and height based on the bounding box dimensions
      const svgElement = document.getElementById("dynamic-svg");
      svgElement.setAttribute("width", bbox.width);
      svgElement.setAttribute("height", bbox.height);

      // You can also adjust the viewBox to keep the content centered
      const viewBox = `${bbox.x} ${bbox.y} ${bbox.width} ${bbox.height}`;
      svgElement.setAttribute("viewBox", viewBox);

      return bbox.width;
    }

    // Function to generate the image source URL
    const getImageSource = (pic) => {
      if (pic && /\.(gif|jpe?g|tiff?|png|webp|bmp)$/i.test(pic)) {
        return getImagePath(pic);
      } else {
        // alert(pic)
      }
      return null; //noImageAvailable;
    };

    const resizeElements = () => {
      const container = document.querySelector(".container");

      // Clear the existing rectangles
      const existingRectangles = document.querySelectorAll(".rectangle");
      for (const rectangle of existingRectangles) {
        container.removeChild(rectangle);
      }

      //   var centerX = container.offsetWidth / 2 + 100;
      //   var centerY = container.offsetHeight / 2;

      // Calculate the circleRadius as 40% of the screen width
      const screenWidth = window.innerWidth;
      const screenHeight = window.innerHeight;
      let circleRadius = 0.2 * screenWidth;

      if (screenWidth > 900) {
        if (screenWidth < screenHeight) {
          circleRadius = 0.35 * screenWidth;
        } else {
          circleRadius = 0.35 * screenHeight;
        }
      } else if (screenWidth > 500) {
        if (screenWidth < screenHeight) {
          circleRadius = 0.3 * screenWidth;
        } else {
          circleRadius = 0.3 * screenHeight;
        }
      }

      var centerX = container.offsetWidth / 2 + 100;
      var centerY = container.offsetHeight / 2;

      // Set the calculated circleRadius as a CSS variable
      document.documentElement.style.setProperty(
        "--circleRadius",
        `${circleRadius}px`
      );

      let rectangleHeight = 0;

      const centralPoint = { x: centerX, y: centerY };
      const numRectangles = numberOfParts;
      const radius = circleRadius;
      const angleIncrement = (2 * Math.PI) / numRectangles;

      // Calculate the width of each rectangle based on the number of rectangles and the circle radius
      const partCircumferanceWidth = (2 * Math.PI * radius) / numRectangles;
      rectangleHeight = partCircumferanceWidth * 2;

      const rectangleWidth = createWheelSectionPathSVG(
        radius,
        numberOfParts,
        rectangleHeight
      );

      for (let i = 0; i < numRectangles; i++) {
        const container = document.querySelector(".container");
        const img = document.createElement("img");

        const rectangle = document.createElement("div");
        rectangle.classList.add("rectangle");
        // rectangle.style.backgroundColor = getRandomColor();
        // background-image: url('data:image/svg+xml;utf8,' + encodeURIComponent(new XMLSerializer().serializeToString(document.querySelector('svg')));
        // background-size: cover; /* Adjust this as needed */

        const svgXML = new XMLSerializer().serializeToString(
          document.querySelector("#dynamic-svg")
        );
        let modifiedSvgXML = "";
        // Check if the fill attribute is already present in the SVG code
        if (!svgXML.includes('fill="')) {
          // If fill attribute is not present, add it with a random color
          const randomFill = `fill="${getRandomColor()}"`;
          modifiedSvgXML = svgXML.replace("<path", `<path ${randomFill}`);
        } else {
          // If fill attribute is already present, replace its value with a random color
          modifiedSvgXML = svgXML.replace(
            /fill="[^"]*"/,
            `fill="${getRandomColor()}"`
          );
        }
        const svgDataUri =
          "data:image/svg+xml," + encodeURIComponent(modifiedSvgXML);
        rectangle.style.backgroundImage = `url("${svgDataUri}")`;

        const angle = i * angleIncrement;
        const x = centralPoint.x + radius * Math.cos(angle) - 100;
        const y =
          centralPoint.y + radius * Math.sin(angle) - parseInt(rectangleHeight);

        rectangle.style.left = x + "px";
        rectangle.style.top = y + "px";
        const rotationAngle = angle + Math.PI / 2;
        rectangle.style.transform = `translateX(-50%) translateY(0%) rotate(${rotationAngle}rad)`;
        // rectangle.style.width = rectangleWidth + 'px';
        // rectangle.style.height = rectangleHeight + 'px';
        rectangle.style.width = rectangleWidth + "px";
        rectangle.style.height = rectangleHeight + "px";

        let randomNumber = i % box?.items?.length;
        let item = box?.items?.[randomNumber];
        let picture = item?.detail?.picture;

        let name = item?.detail?.name;

        img.src = getImageSource(picture);
        img.alt = name;
        // img.alt = randomNumber +" - " + name +"";
        // Add a click event listener to the image
        img.addEventListener("click", () => {
          handleSetProduct(item);
        });

        rectangle.appendChild(img);

        //          onClick={() => handleSetProduct(item)}

        container.appendChild(rectangle);
      }
    };

    const updateContainerMargin = () => {
      const container = document.querySelector(".container");
      const screenWidth = window.innerWidth;

      const screenSizeRanges = [
        { maxWidth: 768, marginPercentage: -20 },
        { maxWidth: 1024, marginPercentage: 10 },
        { maxWidth: 1280, marginPercentage: 30 },
      ];

      let newMargin = 20;

      for (const range of screenSizeRanges) {
        if (screenWidth <= range.maxWidth) {
          newMargin = range.marginPercentage;
          break;
        }
      }

      container.style.marginTop = newMargin + "vh";
    };

    resizeElements();
    updateContainerMargin();

    window.addEventListener("resize", resizeElements);
    document.addEventListener("DOMContentLoaded", resizeElements);

    window.addEventListener("resize", updateContainerMargin);
    document.addEventListener("DOMContentLoaded", updateContainerMargin);

    return () => {
      // Clean up the event listener on unmount
      window.removeEventListener("resize", resizeElements);
      window.removeEventListener("resize", updateContainerMargin);
    };
  }, [numberOfParts, box]);

  return (
    <div className="rootContainer">
      <svg id="dynamic-svg" style={{ fill: "none" }} width="100%" height="100%">
        <path className="wheel" d=""></path>
      </svg>
      <div className={`container ${spinning ? "spin" : ""}`}></div>
    </div>
  );
});

export default WheelOfFortune;
