// src/slices/liveFeedSlice.ts
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const fetchLiveFeed = createAsyncThunk('liveFeed/fetchLiveFeed', async () => {
  const response = await axios.get('/live-feed');
  return response.data;
});

interface LiveFeedState {
  feeds: Array<any>;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
}

const initialState: LiveFeedState = {
  feeds: [],
  status: 'idle',
  error: null, // Explicitly use `null` instead of undefined.
};

const liveFeedSlice = createSlice({
  name: 'liveFeed',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchLiveFeed.pending, (state) => {
        state.status = 'loading';
        state.error = null; // Reset error when starting a new fetch.
      })
      .addCase(fetchLiveFeed.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.feeds = action.payload;
        state.error = null; // Clear error on success.
      })
      .addCase(fetchLiveFeed.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Failed to fetch live feed.';
      });
  },
});

export default liveFeedSlice.reducer;
