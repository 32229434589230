// utility.ts

import { toast } from "react-toastify";
import {
  IMAGE_BASE_PATH,
  SLIDES_DELAY_KEY,
} from "../api/apiConfig";
import { Setting } from "../features/settingssSlice";
import { AppDispatch } from "../app/store";
import { NavigateFunction } from "react-router-dom";
import { selectPage } from "../features/pageSlice";


export const getImagePath = (pic: string): string => {
  return `${IMAGE_BASE_PATH}${pic}`;
};

export const getVideoPath = (video: string | null): string | null => {
  if (!video) {
    return null;
  }
  return `${IMAGE_BASE_PATH}${video}`;
};



export const getImageSource = (pic: string | undefined): string => {
  if (pic && /\.(gif|jpe?g|tiff?|png|webp|bmp)$/i.test(pic)) {
    return getImagePath(pic);
  }
  return "/assets/default-box.png";
};

// Other utility functions can be added here if needed

export const showToast = (message: string, options?: object) => {
  toast(message, options);
};

export function formatNumber(value?: number): string {
  if (value === undefined || value === null) {
    return "0"; // or return any default value or placeholder
  }
  return value % 1 === 0 ? value.toString() : value.toFixed(2);
}

export const formatCurrency = (amount: number) => {
  return new Intl.NumberFormat("en-US", {
    style: "decimal",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(amount);
};

// Utility function to get the "default_box_surcharge" setting, default to 15 if not found
export const getDefaultBoxSlidesDelay = (settings: Setting[]): number => {
  const slidesDelay = settings.find(
    (setting) => setting.setting === SLIDES_DELAY_KEY
  );
  return slidesDelay ? parseFloat(slidesDelay.value) : 7000; // Return the value as a number or default 15
};

export const handleLogoutUser = (
  dispatch: AppDispatch,
  navigate: NavigateFunction
) => {
  showToast("Session expired!", {
    autoClose: 3000, // Auto-close the toast after 3000 milliseconds (3 seconds)
    // Other options can be added here
  });

  localStorage.clear();
  window.location.href = "/";
  dispatch(selectPage("home"));
  navigate(`/`);
};

// Utility function to get the "default_box_surcharge" setting, default to 15 if not found
export const getHotBoxesLimit = (settings: Setting[]): number => {
  const hot_boxes_limit = settings.find((setting) => setting.setting === "hot_boxes_limit");
  return hot_boxes_limit ? parseInt(hot_boxes_limit.value) : 20; // Return the value as a number or default 20
};