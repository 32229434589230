import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { AppDispatch, RootState } from "../app/store";

// Define interfaces for the response structure
interface WalletAddresses {
  avalanche: string | null;
  base_network: string | null;
  bitcoin: string | null;
  destination_tags: string | null;
  ethereum: string;
  polygon: string | null;
  solana: string | null;
  stellar: string | null;
}

interface TransactionDetails {
  destination_amount: number | null;
  destination_currencies: string[];
  destination_currency: string;
  destination_network: string;
  destination_networks: string[];
  fees: number | null;
  lock_wallet_address: boolean;
  source_amount: string;
  source_currency: string;
  transaction_id: string | null;
  wallet_address: string | null;
  wallet_addresses: WalletAddresses;
}

interface CryptoSession {
  id: string;
  object: string;
  client_secret: string;
  created: number;
  kyc_details_provided: boolean;
  livemode: boolean;
  metadata: Record<string, any>;
  redirect_url: string;
  status: string;
  transaction_details: TransactionDetails;
}

// Define the state interface that includes the `CryptoSession` type
interface CryptoState {
  session: CryptoSession | null;
  loading: boolean;
  error: string | null;
}

// Initial state using the `CryptoState` interface
const initialState: CryptoState = {
  session: null,
  loading: false,
  error: null,
};

// Thunk to fetch crypto session data from the API
export const fetchCryptoSession = createAsyncThunk<
  CryptoSession, // Return type (session data)
  void, // Argument type (no arguments in this case)
  {
    dispatch: AppDispatch; // Dispatch type
    state: RootState; // State type
    rejectValue: string; // Optional reject value type (string in this case)
  }
>(
  "crypto/fetchCryptoSession",
  async (_, { getState, rejectWithValue, dispatch }) => {
    try {
      // Optionally, you can access the state if needed (e.g., to retrieve a token)
      const state = getState() as RootState;
      const userId = state.user.user?.id; // Example: if you need userId from state

      // Send POST request to fetch the crypto session
      const response = await axios.get(
        "https://demo1-api.boxchaos.com/api/stripe/mint-onramp-session"
      );

      // Check if response is successful and return session data
      if (response.status === 200) {
        return response.data; // Assuming the session data is in the response's body
      } else {
        return rejectWithValue("Failed to fetch session data");
      }
    } catch (error: any) {
      // Handle other errors and reject with the error message
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue("Failed to fetch session data");
      }
    }
  }
);

// Redux slice for managing the crypto session state
const cryptoSlice = createSlice({
  name: "crypto",
  initialState,
  reducers: {
    // Action to set the entire session object
    setCryptoSession: (state, action: PayloadAction<CryptoSession>) => {
      state.session = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCryptoSession.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCryptoSession.fulfilled, (state, action) => {
        state.loading = false;
        state.session = action.payload; // Directly set the entire session object
      })
      .addCase(fetchCryptoSession.rejected, (state, action) => {
        state.loading = false;
        state.error =
          action.payload ||
          action.error.message ||
          "Failed to fetch session data";
      });
  },
});

export const { setCryptoSession } = cryptoSlice.actions;

export default cryptoSlice.reducer;
