// src/components/BoxDetails.tsx
import React, { SetStateAction, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  fetchBoxDetails,
  selectSelectedBox,
  selectSelectedBoxId,
  selectLoading,
  selectError,
  setBoxDetails,
  fetchItemsForWinning,
  fetchItemsForWinningForVipBox,
  validateEmail,
  sendGift,
  fetchItemsForGiftBox,
} from "../features/boxDetailsSlice";
import { AppDispatch, RootState } from "../app/store";
import styled from "styled-components";
import DynamicItems from "./DynamicItems";
import SlotMachine from "./SlotMachine/SlotMachine";
import Footer from "./Footer";
import { formatNumber, getImageSource, getVideoPath, showToast } from "../utils/utility";
import localization from "../services/localization";
import { Item } from "../utils/boxTypes";
import BackButton from "./BackButton";
import { useParams } from "react-router-dom";
import { setSelectedLevelBoxWithStatus, updateBoxStatus } from "../features/vipSlice";
import HoverableBox from "./HoverableBox";
import { getWinningItemsSubject, getWinningItemsSubjectForAnimation } from "../api/apiConfig";
import BoxWinningDialogue from "./BoxWinningDialogue";
import User from "../utils/user";
import useConfirmation from "./confirm_modal";
import { setSelectedGiftBox } from "../features/receivedBoxesSlice";

const BoxesDetailsWrapper = styled.div`
  padding: 20px;
  color: #fff;
`;

// Styled component for additional vertical space
const Spacer = styled.div`
  height: 100px; /* Adjust the height as needed */
`;

const BoxImageColumn = styled.div`
  flex: 0 0 23%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const BoxImage = styled.img`
  width: 55%;
  height: auto;
  max-width: 100%;
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);

  @media (max-width:400px) {
    position: relative;
    width: 35%;
    left: 20%;
  }
`;

const BoxName = styled.div`
  font-family: Verdana;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fff7cc;
  /* margin: 10px; */
  padding: 15px;
`;

const BoxDescription = styled.div`
  font-family: Verdana;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #a1e0e5;
  padding: 15px;
`;

const PriceRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: center;
  }
`;

const Price = styled.div`
  font-family: Verdana;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fff7cc;
  padding-left: 15px;
`;

const TextAndCheckboxes = styled.div`
  display: flex;
  align-items: center;
  padding: 15px;

  @media (max-width: 400px) {
    flex-direction: column;
    align-items: center;
  }
`;

const Checkboxes = styled.div`
  display: flex;
  align-items: center;
`;

const ActionButton = styled.button`
  width: 142px;
  height: 40px;
  margin-right: 16px;
  margin-bottom: 16px;
  border-radius: 8px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25);
  font-family: Verdana;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  background-color: #00bbcc;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease, box-shadow 0.2s ease;

  // Default shadow effect
  box-shadow: inset 4px 8px 12px 0 rgba(255, 255, 255, 0.5),
    inset -2px -6px 10px 0 rgba(0, 0, 0, 0.6),
    0px 5px 10px rgba(0, 0, 0, 0.2);

  // Hover effect: slight scale up and darker background
  &:hover {
    background-color: #00a0b0;
    transform: scale(1.05); // Slight scale up on hover
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.3); // More pronounced shadow on hover
  }

  // Active (press) effect: scale down and change shadow
  &:active {
    background-color: #008c9e;
    transform: scale(0.95); // Scale down when clicked
    box-shadow: inset 4px 6px 10px rgba(0, 0, 0, 0.8), 0px 4px 8px rgba(0, 0, 0, 0.3); // Stronger shadow inset
  }

  // Disabled state: gray background, no hover or click effect
  &:disabled {
    background-color: #b0b0b0;
    cursor: not-allowed;
    box-shadow: none;
    color: #888;
    transform: none; // No scale effects
  }

  @media (max-width: 600px) {
    margin-top: 8px;
  }
`;

const TwoPartContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-top: 16px;
  
  @media (max-width:400px) {
    flex-direction: column;
  }
`;

const FirstPart = styled.div`
  flex: 0 0 20%;
  /* Add your styling for the first part here */
  background-color: rgba(0, 170, 255, 0.05);
  padding-top: 16px;
`;

const SecondPart = styled.div`
  flex: 1;
  /* Add your styling for the second part here */
  display: flex;
  flex-direction: column;
  background-color: rgba(0, 170, 255, 0.05);
`;

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Text = styled.div`
  font-family: Verdana;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #a1e0e5;
`;

const ButtonSelectionButton = styled.button`
  background-color: lightgray;
  color: rgb(88, 87, 87);
  border: none;
  /* padding: 5px 10px; */
  margin: 5px;
  cursor: pointer;
  /* border-radius: 10px; */
  font-size: 14px;
  border-radius: 4px;
  border: solid 1px rgba(255, 251, 229, 0.4);

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }

  &.selected {
    /* background-color: #6629fa; */
    border-radius: 4px;
    border: solid 1px rgba(255, 251, 229, 0.8);
    background-color: rgba(255, 251, 229, 0.4);
    color: #ffffff;
  }
`;

const IntroOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Fallback background color */
  backdrop-filter: blur(10px); /* Applies the blur effect */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  z-index: 100;
  padding: 20px;
  text-align: center;

  h1 {
    font-size: 2rem;
    margin-bottom: 1rem;
  }

  p {
    font-size: 1.2rem;
    margin-bottom: 2rem;
  }

  button {
    padding: 0.8rem 1.5rem;
    font-size: 1rem;
    font-weight: bold;
    background-color: #0bc;
    color: #fff;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);

    &:hover {
      background-color: #099;
    }
  }
`;


const FixedSizeVideoWrapper = styled.div`
  width: 400px; /* Fixed width for the video */
  height: 300px; /* Fixed height for the video */
  margin-top: 1rem;

  video {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures the video fills the container without distortion */
    border-radius: 8px; /* Optional: adds rounded corners to the video */
  }
`;

const BoxDetails: React.FC = () => {
  const dispatch: AppDispatch = useDispatch(); // Use AppDispatch


  const { boxId } = useParams<{ boxId: string }>();

  // const dispatch = useDispatch();
  const selectedLevelBoxWithStatus = useSelector((state: RootState) => state.vip.selectedLevelBoxWithStatus);

  const selectedGiftBox = useSelector((state: RootState) => state.receivedGiftBoxes.selectedGiftBox);


  const selectedBoxId = useSelector(selectSelectedBoxId);
  const selectedBox = useSelector(selectSelectedBox);
  const loading = useSelector(selectLoading);
  const error = useSelector(selectError);

  const [itemsCount, setItemsCount] = useState(2);
  const contentRef = useRef<HTMLDivElement>(null);
  const [numColumns, setNumColumns] = useState(3);
  const [selectedSlots, setSelectedSlots] = useState(3); // Initialize with a default value


  const isLoadingWinningItem = useSelector((state: RootState) => state.boxDetails.isLoadingWinningItem);
  const balance = useSelector((state: RootState) => state.user.balance); // Replace with your balance selector

  const boxVideos = useSelector((state: RootState) => state.boxDetails.boxVideos);

  const [openingVideo, setOpeningVideo] = useState<string | null>(null);

  useEffect(() => {
    if (boxVideos && boxVideos.length > 0) {
      const openingVideoElement = boxVideos.find(video => video.type === "box_opening");
      if (openingVideoElement) {
        setOpeningVideo(openingVideoElement.file); // Use the video file from the element
      } else {
        setOpeningVideo(null); // Default to null if no element with type "box_opening"
      }
    } else {
      setOpeningVideo(null); // Default to null if boxVideos is empty or null
    }
  }, [boxVideos]);
  // Create a ref to the SlotMachine component
  const slotMachineRef = useRef<HTMLButtonElement>(null);

  const [showDialogue, setShowDialogue] = useState(false); // Replacing overlay logic
  const [winningItems, setWinningItem] = useState<Item[] | null>(null);

  useEffect(() => {
    setShowDialogue(false); // Show overlay when a winning item is emitted
    setWinningItem(null);

    // Subscribe to the ReplaySubject
    // Subscribe to ReplaySubject
    const subscription = getWinningItemsSubjectForAnimation.subscribe({
      next: (state) => {
        if (state.items) {
          setWinningItem(state.items);
          // Add a delay before setting the overlay
          setTimeout(() => {
            setShowDialogue(true);
          }, 1000);
        }
      },
    });

    return () => {
      subscription.unsubscribe(); // Cleanup subscription
    };
  }, []);

  // Reset ReplaySubject when component unmounts
  useEffect(() => {
    return () => {
      getWinningItemsSubjectForAnimation.next({ items: null }); // Clear the ReplaySubject
    };
  }, []);

  useEffect(() => {

    if (selectedLevelBoxWithStatus != null) {
      handleSlotChange(1);
    }

  }, [selectedLevelBoxWithStatus]);

  useEffect(() => {

    if (selectedGiftBox != null) {
      handleSlotChange(1);
    }

  }, [selectedGiftBox]);

  const handleSlotChange = (newNumColumns: SetStateAction<number>) => {
    setNumColumns(newNumColumns);
    setSelectedSlots(newNumColumns); // Update the selected state
  };


  const isBalanceIsEnough = (): boolean => {
    if (balance != null) {
      const numericBalance = parseFloat(balance);

      // Ensure numericBalance is a valid number
      if (isNaN(numericBalance)) {
        console.error("Invalid balance value.");
        return false;
      }

      const value = selectedBox?.final_value ?? 0;

      // Compare and return the result
      return numericBalance < value;
    }

    // If balance is null, return false
    return false;
  };


  // Define a function to trigger a click in the SlotMachine component
  const triggerClickInSlotMachine = () => {
    // Use the ref to access the SlotMachine component and trigger a click
    if (slotMachineRef.current) {
      // Access the button element here
      if (!!selectedBox) {
        const TOKEN = localStorage.getItem("token");

        if (isLoadingWinningItem) {
          return;
        }

        if (TOKEN != null) {

          if (isBalanceIsEnough()) {
            showToast("Not enough balance to open the box.", {
              autoClose: 3000, // Auto-close the toast after 3000 milliseconds (3 seconds)
              // Other options can be added here
            });
            return;
          }

          // Dispatch the async action
          dispatch(fetchItemsForWinning({ boxId: selectedBox.id, times: numColumns }))
            .then((action) => {
              // Check if the payload is of the correct type
              if (fetchItemsForWinning.fulfilled.match(action)) {
                const result: Item[] = action.payload;
                console.log(result);

                // Combine all item details into a single message
                const toastMessage = result
                  .map((item) => `${item.detail?.name}, $${item.detail?.price}`)
                  .join('\n');

                // Show a single toast with the combined message
                showToast(toastMessage, {
                  autoClose: 3000, // Auto-close the toast after 3000 milliseconds (3 seconds)
                  // Additional options can be added here if needed
                });

                getWinningItemsSubject.next(result);
                slotMachineRef.current?.click();
                // Perform the next action
              } else {
                // Handle unexpected action type or payload structure
                alert("Unexpected action type or payload structure");
              }
            })
            .catch((error: string) => {
              // Handle errors if needed
              alert(error);
            });
        } else {
          slotMachineRef.current?.click();
        }
      }

      // slotMachineRef.current?.click();
    }
  };

  // Define a function to trigger a click in the SlotMachine component
  const triggerVipBoxClickInSlotMachine = () => {
    // Use the ref to access the SlotMachine component and trigger a click
    if (slotMachineRef.current) {
      // Access the button element here
      if (!!selectedBox) {
        const TOKEN = localStorage.getItem("token");

        if (isLoadingWinningItem) {
          return;
        }

        if (TOKEN != null) {

          // Dispatch the async action
          dispatch(fetchItemsForWinningForVipBox({ boxId: selectedBox.id }))
            .then((action) => {
              // Check if the payload is of the correct type
              if (fetchItemsForWinningForVipBox.fulfilled.match(action)) {
                const result: Item = action.payload;
                console.log(result);

                // Combine all item details into a single message
                const toastMessage = `${result.detail?.name}, $${result.detail?.price}`;

                // Show a single toast with the combined message
                showToast(toastMessage, {
                  autoClose: 3000, // Auto-close the toast after 3000 milliseconds (3 seconds)
                  // Additional options can be added here if needed
                });

                getWinningItemsSubject.next([result]);
                slotMachineRef.current?.click();

                dispatch(updateBoxStatus("opened"));
                // Perform the next action
              } else {
                // Handle unexpected action type or payload structure
                alert("Unexpected action type or payload structure");
              }
            })
            .catch((error: string) => {
              // Handle errors if needed
              alert(error);
            });
        } else {
          slotMachineRef.current?.click();
        }
      }

      // slotMachineRef.current?.click();
    }
  };


  const triggerGiftBoxSlotMachine = () => {
    // Use the ref to access the SlotMachine component and trigger a click
    if (slotMachineRef.current) {
      // Access the button element here
      if (!!selectedBox) {
        const TOKEN = localStorage.getItem("token");

        if (isLoadingWinningItem) {
          return;
        }

        if (TOKEN != null) {

          // Dispatch the async action
          dispatch(fetchItemsForGiftBox({ boxId: selectedGiftBox?.id ?? "" }))
            .then((action) => {
              // Check if the payload is of the correct type
              if (fetchItemsForWinningForVipBox.fulfilled.match(action)) {
                const result: Item = action.payload;
                console.log(result);

                // Combine all item details into a single message
                const toastMessage = `${result.detail?.name}, $${result.detail?.price}`;

                // Show a single toast with the combined message
                showToast(toastMessage, {
                  autoClose: 3000, // Auto-close the toast after 3000 milliseconds (3 seconds)
                  // Additional options can be added here if needed
                });

                getWinningItemsSubject.next([result]);
                slotMachineRef.current?.click();


                // dispatch(updateBoxStatus("opened"));
                // Perform the next action
              } else {
                // Handle unexpected action type or payload structure
                alert("Unexpected action type or payload structure");
              }
            })
            .catch((error: string) => {
              // Handle errors if needed
              alert(error);
            });
        } else {
          slotMachineRef.current?.click();
        }
      }

      // slotMachineRef.current?.click();
    }
  };

  useEffect(() => {
    const handleResize = () => {
      const windowWidth = window.innerWidth;
      if (windowWidth < 600) {
        setItemsCount(2);
      } else if (windowWidth < 768) {
        setItemsCount(3);
      } else if (windowWidth < 1024) {
        setItemsCount(4);
      } else {
        setItemsCount(6);
      }
    };

    const handleContentHeight = () => {
      if (contentRef.current) {
        const contentHeight = contentRef.current.scrollHeight;
        const windowHeight = window.innerHeight;
        const newMaxHeight = Math.min(contentHeight, windowHeight);
        contentRef.current.style.maxHeight = `${newMaxHeight}px`;
      }
    };

    window.addEventListener("resize", handleResize);
    window.addEventListener("resize", handleContentHeight);

    handleResize();
    handleContentHeight();

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("resize", handleContentHeight);
    };
  }, []);

  // Fetch box details when the component mounts
  useEffect(() => {
    if (selectedBoxId) {
      dispatch(fetchBoxDetails(selectedBoxId));
    } else {
      const idToUse = selectedBoxId || boxId;

      // Check if idToUse is a string before dispatching
      if (idToUse) {
        dispatch(fetchBoxDetails(idToUse));
      }

    }

    // Check if the path includes '/vip/' and perform cleanup accordingly
    const isVipPath = window.location.pathname.includes('/vips/');
    const isGiftsPath = window.location.pathname.includes('/gifts/');


    // Cleanup - reset selectedBoxId when the component unmounts
    return () => {
      dispatch(setBoxDetails(null));

      if (!isVipPath) {
        dispatch(setSelectedLevelBoxWithStatus(null));
      }

      if (!isGiftsPath) {
        dispatch(setSelectedGiftBox(null));
      }
    };
  }, [dispatch, selectedBoxId]);

  if (loading) {
    return <div>{localization.loading}</div>;
  }

  if (error) {
    return <div>{localization.error} {error}</div>;
  }

  if (!selectedBox) {
    return <div>{localization.no_box_detail_available}</div>;
  }

  const handleTryAgain = () => {
    setShowDialogue(false);
    // Add any additional logic for "Try Again" here
  };

  const handleSell = () => {
    showToast("Items sold successfully!", { autoClose: 3000 });
    setShowDialogue(false);
    // Add any logic for selling items here
  };

  return (
    <>
      {/* Winning Dialogue */}
      {showDialogue && (
        <BoxWinningDialogue
          winningItems={winningItems}
          boxVideos={boxVideos}
          onClose={() => setShowDialogue(false)}
        />
      )}



      <BoxesDetailsWrapper ref={contentRef}>
        <SlotMachine
          numColumns={numColumns}
          box={selectedBox}
          ref={slotMachineRef}
        />
        <TwoPartContainer>
          <FirstPart>
            <BackButton />
            <HoverableBox
              selectedBox={{
                picture: getImageSource(selectedBox.picture), // Replace with your image URL
                video: getVideoPath(openingVideo), // Replace with your video URL
              }}
            />
          </FirstPart>
          <SecondPart>
            <ColumnContainer>
              <BoxName>{selectedBox.name}</BoxName>
              <BoxDescription>{selectedBox.description}</BoxDescription>


              {selectedLevelBoxWithStatus == null && selectedGiftBox == null ? (
                <Price>${formatNumber(selectedBox.final_value*numColumns)}</Price>
              ) : null}

              <PriceRow>
                <TextAndCheckboxes>
                  {selectedLevelBoxWithStatus == null && selectedGiftBox == null ? (
                    <Text>Number Of Boxes</Text>
                  ) : <Spacer />}


                  {selectedLevelBoxWithStatus == null && selectedGiftBox == null ? (
                    <Checkboxes>
                      <ButtonSelectionButton
                        className={selectedSlots === 1 ? "selected" : ""}
                        onClick={() => handleSlotChange(1)}
                      >
                        {localization.one}
                      </ButtonSelectionButton>

                      <ButtonSelectionButton
                        className={selectedSlots === 2 ? "selected" : ""}
                        onClick={() => handleSlotChange(2)}
                      >
                        {localization.two}
                      </ButtonSelectionButton>

                      <ButtonSelectionButton
                        className={selectedSlots === 3 ? "selected" : ""}
                        onClick={() => handleSlotChange(3)}
                      >
                        {localization.three}
                      </ButtonSelectionButton>

                      <ButtonSelectionButton
                        className={selectedSlots === 4 ? "selected" : ""}
                        onClick={() => handleSlotChange(4)}
                      >
                        {localization.four}
                      </ButtonSelectionButton>

                      <ButtonSelectionButton
                        className={selectedSlots === 5 ? "selected" : ""}
                        onClick={() => handleSlotChange(5)}
                      >
                        {localization.five}
                      </ButtonSelectionButton>
                    </Checkboxes>
                  ) : <Spacer />}

                </TextAndCheckboxes>
                {
                  (selectedLevelBoxWithStatus == null) ? (
                    <ActionButton
                      disabled={selectedBox.retired === true || (selectedGiftBox != null && selectedGiftBox.opened === true)}
                      onClick={selectedGiftBox ? triggerGiftBoxSlotMachine : triggerClickInSlotMachine}
                    >
                      {localization.open_box}
                    </ActionButton>
                  ) : (
                    <ActionButton
                      disabled={selectedLevelBoxWithStatus.status === "locked" || selectedLevelBoxWithStatus.status === "opened" || (selectedGiftBox != null && selectedGiftBox.opened === true)}
                      onClick={selectedGiftBox ? triggerGiftBoxSlotMachine : triggerVipBoxClickInSlotMachine}
                    >
                      {localization.open_box}
                    </ActionButton>
                  )
                }

              </PriceRow>
            </ColumnContainer>
          </SecondPart>
        </TwoPartContainer>
        <DynamicItems itemsCount={itemsCount} box={selectedBox} />
        <Footer /> {/* Add the Footer component */}
        <Spacer /> {/* Add additional empty vertical space */}
      </BoxesDetailsWrapper>
    </>
  );
};

export default BoxDetails;



const EmailInput = styled.input`
  flex-grow: 1;
  margin-top: 8px;
  width: 100%;
  color: black;
  border-radius: 4px;
  background-color: #cfdee5;
  width: 250px;
`;

export const GifBoxDetails: React.FC = () => {
  const dispatch: AppDispatch = useDispatch(); // Use AppDispatch
  const selectedBoxId = useSelector(selectSelectedBoxId);
  const selectedBox = useSelector(selectSelectedBox);
  const loading = useSelector(selectLoading);
  const error = useSelector(selectError);
  const validateUserStatus = useSelector((state: RootState) => state.boxDetails.statusForValidateUser);
  const validateUserError = useSelector((state: RootState) => state.boxDetails.validateUserError);
  const validatedUser = useSelector((state: RootState) => state.boxDetails.validatedUser);
  const giftCategoryName = useSelector(
    (state: RootState) => state.allBoxes.giftCategoryName
  );


  const [itemsCount, setItemsCount] = useState(2);
  const [email, setEmail] = useState<string>("");
  const [emailError, setEmailError] = useState<string | null>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const boxVideos = useSelector((state: RootState) => state.boxDetails.boxVideos);

  const [openingVideo, setOpeningVideo] = useState<string | null>(null);
  const { confirm, ConfirmationModal } = useConfirmation();

  const validateLocalEmail = (email: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Standard email regex
    return emailRegex.test(email);
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setEmail(value);

    if (!validateLocalEmail(value)) {
      setEmailError("Invalid email address.");
    } else {
      setEmailError(null);
    }
  };

  const processGiftCategoryName = (giftCategoryName: String) => {
    // Convert to lowercase
    const lowercased = giftCategoryName.toLowerCase();

    // Remove " boxes" from the string
    const processed = lowercased.replaceAll(" boxes", "");

    return processed;
  }



  const handleValidateEmail = () => {
    if (!validateLocalEmail(email)) {
      showToast("Invalid email address.", {
        autoClose: 3000,
      });
      return;
    }

    dispatch(validateEmail(email))
      .then(async (action) => {
        if (validateEmail.fulfilled.match(action)) {
          const result: User = action.payload;

          const isConfirmed = await confirm(
            <>
              <p>
                Are you sure you want to send this box{" "}
                <strong style={{ color: "green" }}>{selectedBox?.name}</strong>{" "}
                of price{" "}
                <strong style={{ color: "green" }}>
                  ${selectedBox?.final_value}
                </strong>
                ?
              </p>
              <p>
                It will be sent to{" "}
                <strong style={{ color: "orange" }}>
                  {result.first_name} {result.last_name}
                </strong>{" "}
                with email{" "}
                <strong style={{ color: "orange" }}>{result.email}</strong>.
              </p>
              <p>Do you want to proceed?</p>
            </>
          );
          if (!isConfirmed) return;

          const gift_class = processGiftCategoryName(giftCategoryName);

          dispatch(
            sendGift({
              receiver_email: result.email,
              box_id: selectedBox?.id ?? "",
              gift_class: gift_class,
            })
          )
            .unwrap()
            .then((giftResult) => {
              showToast(`Success: ${giftResult.message}`, {
                autoClose: 3000,
              });
            })
            .catch((giftError) => {
              showToast(
                giftError || "Failed to send the gift. Please try again.",
                {
                  autoClose: 3000,
                }
              );
            });
        } else if (validateEmail.rejected.match(action)) {
          const error = action.payload as string;
          showToast(error || "Email validation failed.", {
            autoClose: 3000,
          });
        }
      })
      .catch((error: string) => {
        showToast(error || "An unexpected error occurred.", {
          autoClose: 3000,
        });
      });
  };



  useEffect(() => {
    if (boxVideos && boxVideos.length > 0) {
      const openingVideoElement = boxVideos.find(video => video.type === "box_opening");
      if (openingVideoElement) {
        setOpeningVideo(openingVideoElement.file); // Use the video file from the element
      } else {
        setOpeningVideo(null); // Default to null if no element with type "box_opening"
      }
    } else {
      setOpeningVideo(null); // Default to null if boxVideos is empty or null
    }
  }, [boxVideos]);

  useEffect(() => {
    const handleResize = () => {
      const windowWidth = window.innerWidth;
      if (windowWidth < 600) {
        setItemsCount(2);
      } else if (windowWidth < 768) {
        setItemsCount(3);
      } else if (windowWidth < 1024) {
        setItemsCount(4);
      } else {
        setItemsCount(6);
      }
    };

    const handleContentHeight = () => {
      if (contentRef.current) {
        const contentHeight = contentRef.current.scrollHeight;
        const windowHeight = window.innerHeight;
        const newMaxHeight = Math.min(contentHeight, windowHeight);
        contentRef.current.style.maxHeight = `${newMaxHeight}px`;
      }
    };

    window.addEventListener("resize", handleResize);
    window.addEventListener("resize", handleContentHeight);

    handleResize();
    handleContentHeight();

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("resize", handleContentHeight);
    };
  }, []);

  // Fetch box details when the component mounts
  useEffect(() => {
    if (selectedBoxId) {
      dispatch(fetchBoxDetails(selectedBoxId));
    }
    // Cleanup - reset selectedBoxId when the component unmounts
    return () => {
      dispatch(setBoxDetails(null));
    };
  }, [dispatch, selectedBoxId]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!selectedBox) {
    return <div>No box details available</div>;
  }

  return (
    <BoxesDetailsWrapper ref={contentRef}>
      <ConfirmationModal />
      <TwoPartContainer>
        <FirstPart>
          <BackButton />
          <HoverableBox
            selectedBox={{
              picture: getImageSource(selectedBox.picture), // Replace with your image URL
              video: getVideoPath(openingVideo), // Replace with your video URL
            }}
          />
        </FirstPart>
        <SecondPart>
          <ColumnContainer>
            <BoxName>{selectedBox.name}</BoxName>
            <BoxDescription>{selectedBox.description}</BoxDescription>
            <Price>${selectedBox.final_value}</Price>
            <PriceRow>
              <TextAndCheckboxes>
                <ColumnContainer>
                  <Text>Send this Box to</Text>
                  <EmailInput
                    type="text"
                    placeholder={localization.email_input_placeholder}
                    value={email}
                    onChange={handleEmailChange}
                    style={{
                      borderColor: emailError ? "red" : "black",
                    }}
                  />
                  {emailError && (
                    <small style={{ color: "red" }}>{emailError}</small>
                  )}
                  {validateUserStatus === "failed" && validateUserError && (
                    <small style={{ color: "red" }}>{validateUserError}</small>
                  )}
                </ColumnContainer>
              </TextAndCheckboxes>
              <ActionButton
                onClick={handleValidateEmail}
                disabled={validateUserStatus === "loading"}
              >
                {validateUserStatus === "loading"
                  ? localization.loading
                  : localization.send}
              </ActionButton>
            </PriceRow>
          </ColumnContainer>
        </SecondPart>
      </TwoPartContainer>
      <DynamicItems itemsCount={itemsCount} box={selectedBox} />
      <Footer /> {/* Add the Footer component */}
      <Spacer /> {/* Add additional empty vertical space */}
    </BoxesDetailsWrapper>
  );
};

