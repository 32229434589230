import React, { useEffect, useState, useRef } from "react";
import styled, { keyframes } from "styled-components";
import giftBoxes, { GiftBox } from "../utils/boxTypes";
import BoxesPageHeading from "./BoxesPageHeading";
import Footer from "./Footer";
import localization from "../services/localization";
import { useNavigate } from "react-router-dom";
import { setGiftCategoryName } from "../features/allBoxesSlice";
import { AppDispatch } from "../app/store";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../app/store";
import { css } from "styled-components";
import { fetchReceivedGiftBoxes, setSelectedGiftBox } from "../features/receivedBoxesSlice";
import { getImagePath } from "../utils/utility";
import { setBoxDetails } from "../features/boxDetailsSlice";
import SentGiftsComponent from "./SentGiftsComponent";
import { ImCheckmark } from "react-icons/im";


const GiftsPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: 20px;
  box-sizing: border-box;
  color: #fff;
  overflow: hidden; /* Prevent nested scrolling */
`;


const ContentWrapper = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  padding-bottom: 20px;
`;

const Spacer = styled.div`
  height: 100px;
`;

const SectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
`;

const SectionTitle = styled.h2`
  color: #fff;
  font-size: 1.5rem;
    font-family: 'Corbel', sans-serif; /* Use Corbel font */

`;

const ViewAllButton = styled.button`
  padding: 10px 20px;
  background-color: #ffd400;
  color: #000;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
      font-family: 'Corbel', sans-serif; /* Use Corbel font */
  &:hover {
    background-color: #ffc107;
  }
`;

const SendGiftGrid = styled.div<{ items: number }>`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 16px;
  margin: 20px 0;
`;

const SquareGrid = styled.div<{ columns: number }>`
  display: grid;
  grid-template-columns: repeat(${(props) => props.columns}, 1fr);
  gap: 28px;
  margin: 20px 0;
`;

const colorTuples = [
  ["#33261a", "#1a130d"],
  ["#333333", "#1a1a1a"],
  ["#332b1a", "#1a160d"],
  ["#1a3333", "#0d1a17"],
  ["#252433", "#12131a"],

  // ["#a46628", "#8b5e3c"], // Bronze gradient
  // ["#c0c0c0", "#a8a8a8"], // Silver gradient
  // ["#ffd700", "#ffc107"],   // Gold gradient
  // ["#e5e4e2", "#c5c7c6"], // Platinum gradient
  // ["#b9f2ff", "#87cefa"], // Diamond 
];

const colorTuplesBorder = [
  ["#a46628", "#8b5e3c"], // Bronze gradient
  ["#c0c0c0", "#a8a8a8"], // Silver gradient
  ["#ffd700", "#ffc107"],   // Gold gradient
  ["#e5e4e2", "#c5c7c6"], // Platinum gradient
  ["#b9f2ff", "#87cefa"], // Diamond 
];


// const SendGiftBox = styled.div<{ gradientStartColor: string; gradientEndColor: string }>`
//   background: linear-gradient(145deg, ${(props) => props.gradientStartColor}, ${(props) => props.gradientEndColor});
//   padding: 10px;
//   text-align: center;
//   border-radius: 10px;
//   cursor: pointer;
//   transition: transform 0.3s;
//   &:hover {
//     transform: scale(1.05);
//   }
// `;

// const SendGiftBox = styled.div<{ gradientStartColor: string; gradientEndColor: string, borderColor: string }>`
//   background: linear-gradient(145deg, ${(props) => props.gradientStartColor}, ${(props) => props.gradientEndColor});
//   border: 4px solid ${(props) => props.borderColor}; /* Wider border using start color */
//   padding: 10px;
//   text-align: center;
//   border-radius: 16px; /* Rounded corners for the box */
//   cursor: pointer;
//   transition: transform 0.3s, border-color 0.3s;

//   &:hover {
//     transform: scale(1.05);
//   }
// `;

const SendGiftBox = styled.div<{ gradientStartColor: string; gradientEndColor: string, borderColor: string }>`
  background: linear-gradient(145deg, ${(props) => props.gradientStartColor}, ${(props) => props.gradientEndColor});
  border: 4px solid ${(props) => props.borderColor}; /* Wider border using start color */
  padding: 10px;
  text-align: center;
  border-radius: 20px; /* Rounded corners for the box */
  cursor: pointer;
  transition: transform 0.3s, border-color 0.3s;
  position: relative; /* Necessary for positioning child elements like GiftTitle */
  
  &:hover {
    transform: scale(1.05);
    border-color: ${(props) => props.gradientEndColor}; /* Border color changes on hover */
  }
`;




const GiftImageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 100%; /* Maintain square aspect ratio */
`;

const GiftImageBase = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain; /* Ensure the image scales properly */
`;

const GiftImageOverlay = styled.img`
  position: absolute;
  bottom: 10%;
  left: 50%;
  transform: translateX(-50%);
  width: 80%;
  height: auto;
  object-fit: contain;
`;

export const SquareBox = styled.div`
  width: 100%;
  padding-top: 121.4%;
  position: relative;
  border-radius: 16px;
  font-family: SonsieOne;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  background-image: url("/assets/Box_Container_BG.png"); /* Add your image path here */
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  overflow: visible; /* Ensure content doesn't affect the box size */
`;

export const BoxContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px; /* Adjust padding as needed */
  box-sizing: border-box;
`;

// New styled components for additional elements
// Define a custom prop interface
interface CheckMarkProps {
  isVisible: boolean;
}

// Apply the custom prop interface to the styled component
const CheckMark = styled.img<CheckMarkProps>`
  position: absolute;
  top: 9%;
  left: 5%;
  width: 15px;
  height: 15px;
  display: ${(props) => (props.isVisible ? "block" : "none")};
`;

export const BoxImage = styled.img<{ $isHovered: boolean }>`
  width: 71.4%;
  object-fit: cover;
  position: absolute;
  top: ${(props) => (props.$isHovered ? "8%" : "14%")};
  left: 50%;
  transform: translateX(-50%);
  transition: top 0.3s ease-in-out; /* Add smooth transition effect */
`;

interface BoxTitleProps {
  isOpened: boolean;
}

export const BoxTitle = styled.h3<BoxTitleProps>`
    font-family: 'Corbel', sans-serif; /* Use Corbel font */
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  color: #80d5ff; /* Light blue color as in screenshot */
  background-color: ${(props) => (props.isOpened ? '#333333' : '#3a4000')}; /* Conditional background color */
  padding: 4px 8px; /* Padding around the text */
  border-radius: 4px; /* Rounded edges for the background */
  position: absolute;
  bottom: 5%; /* Position at the bottom */
  left: 50%;
  transform: translateX(-50%); /* Center horizontally */
  margin: 0; /* Remove default margin */
  width: 90%; /* Adjust width if necessary */
`;


// const GiftTitle = styled.h4<{ gradientStartColor: string }>`
//   font-family: Verdana, sans-serif;
//   font-size: 16px;
//   font-weight: bold;
//   text-align: center;
//   color: #ffffff; /* White text */
//   background-color: ${(props) => props.gradientStartColor}; /* Dynamic background color */
//   padding: 8px 0; /* Add vertical padding for better spacing */
//   border-radius: 0 0 16px 16px; /* Rounded bottom corners */
//   position: absolute;
//   bottom: 0; /* Position it at the bottom */
//   left: 0;
//   width: 100%; /* Full width */
//   box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3); /* Ribbon shadow effect */
//   text-transform: uppercase; /* Make text uppercase for emphasis */
//   margin: 0; /* Remove default margin */
// `;

const GiftTitle = styled.h4<{ gradientStartColor: string }>`
  font-family: 'Corbel', sans-serif; /* Use Corbel font */
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  color:rgba(7, 0, 0, 0.8); /* White text */
  background-color: ${(props) => props.gradientStartColor}; /* Dynamic background color */
  padding: 4px 0; /* Add vertical padding for better spacing */
  border-radius: 0 0 16px 16px; /* Rounded bottom corners */
  position: absolute;
  bottom: 0; /* Position it at the bottom of the box */
  left: 0;
  width: 100%; /* Full width of the box */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3); /* Ribbon shadow effect */
  text-transform: uppercase; /* Make text uppercase for emphasis */
  margin: 0; /* Remove default margin */
  z-index: 2; /* Ensure it is on top of other elements */
`;




interface CheckMarkProps {
  isVisible: boolean;
}

// Define a subtle pop-in animation
const popIn = keyframes`
  0% {
    transform: scale(0.8);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`;

const CheckMarkIcon = styled(ImCheckmark) <CheckMarkProps>`
  position: absolute;
  top: 9%;
  left: 5%;
  font-size: 32px; /* Larger size for prominence */
  color: #28a745; /* Vibrant green color */
  background: #f0f8f5; /* Subtle light background to highlight the icon */
  border-radius: 50%; /* Circular background */
  padding: 10px; /* Space around the icon */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Shadow for a floating effect */
  display: ${(props) => (props.isVisible ? "block" : "none")};
  animation: ${popIn} 0.3s ease-in-out; /* Smooth pop-in animation */
`;

const StyledH2 = styled.h2`
  font-family: 'Corbel', sans-serif; /* Use Corbel font */
  font-size: 1.8rem; /* Adjust size as needed */
  font-weight: bold; /* Make it bold for emphasis */
  color: #ffffff; /* Default color, can be customized */
  text-align: center; /* Center alignment */
  margin: 20px 0; /* Add vertical spacing */
  line-height: 1.5; /* Adjust line height for readability */
`;

const GiftsPage: React.FC = () => {
  const [boxCount, setBoxCount] = useState(3);
  const contentRef = useRef<HTMLDivElement>(null);

  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const { recievedGiftBoxes, isLoading } = useSelector((state: RootState) => state.receivedGiftBoxes);
  const [rowsToShow, setRowsToShow] = useState(2);

  const handleSendBoxClick = (boxId: string, name: string) => {
    dispatch(setGiftCategoryName(name));
    navigate(`/gifts/${name.replace(/\s/g, "-")}`);
  };

  const handleBoxClick = (gift: GiftBox) => {
    // if(!gift.opened){
    let box = gift.box_details;
    dispatch(setBoxDetails(box.id));
    dispatch(setSelectedGiftBox(gift));
    navigate(`/gifts/received/${box.id}`);
    // }
  };

  const handleViewAll = () => {
    navigate("/gifts/received");

  };

  useEffect(() => {
    dispatch(fetchReceivedGiftBoxes());
  }, [dispatch]);

  useEffect(() => {
    const handleResize = () => {
      const windowWidth = window.innerWidth;
      if (windowWidth < 768) {
        setBoxCount(2);
      } else if (windowWidth < 1024) {
        setBoxCount(3);
      } else {
        setBoxCount(5);
      }
    };

    const handleContentHeight = () => {
      if (contentRef.current) {
        const contentHeight = contentRef.current.scrollHeight;
        const windowHeight = window.innerHeight;
        const newMaxHeight = Math.min(contentHeight, windowHeight - 100); // Adjust based on spacing
        contentRef.current.style.maxHeight = `${newMaxHeight}px`;
      }
    };

    window.addEventListener("resize", handleResize);
    window.addEventListener("resize", handleContentHeight);

    handleResize();
    handleContentHeight();

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("resize", handleContentHeight);
    };
  }, []);

  const visibleGifts = recievedGiftBoxes.slice(0, rowsToShow * boxCount); // Show `rowsToShow` rows dynamically
  const [hoveredBox, setHoveredBox] = useState<string | null>(null);

  const boxGradients = {
    Bronze: ["#a46628", "#8b5e3c"], // Bronze gradient
    Silver: ["#c0c0c0", "#a8a8a8"], // Silver gradient
    Gold: ["#ffd700", "#ffc107"],   // Gold gradient
    Platinum: ["#e5e4e2", "#c5c7c6"], // Platinum gradient
    Diamond: ["#b9f2ff", "#87cefa"], // Diamond gradient
  };



  return (
    <GiftsPageWrapper>

      <ContentWrapper ref={contentRef}>
        <BoxesPageHeading $gradientColors={["#ffd400 0%", "#0af 100%"]}>
          {localization.gifts}
        </BoxesPageHeading>

        <SectionTitle>Send Gifts</SectionTitle>
        <SendGiftGrid items={giftBoxes.length}>
          {giftBoxes.map((box, index) => {
            const [gradientStartColor, gradientEndColor] =
              colorTuples[index % colorTuples.length]; // Use modulo to loop through colors

              const [gradientStartColorFrame, gradientEndColorFrame] =
              colorTuplesBorder[index % colorTuplesBorder.length]; // Use modulo to loop through colors


              
            return (
              <SendGiftBox
                key={index}
                gradientStartColor={gradientStartColor}
                gradientEndColor={gradientEndColor}
                borderColor={gradientStartColorFrame}
                onClick={() => handleSendBoxClick(box.id, box.name)}
              >
                <GiftImageWrapper>
                  {/* Base image */}
                  <GiftImageBase src="/assets/gift-box.png" alt="Gift Box" />
                  {/* Overlay image */}
                  <GiftImageOverlay src={box.picture} alt={box.name} />
                </GiftImageWrapper>
                <GiftTitle gradientStartColor={gradientStartColorFrame}>
                  {box.name}
                </GiftTitle>
              </SendGiftBox>
            );
          })}

        </SendGiftGrid>

        <SectionHeader>
          <SectionTitle>Received Gifts</SectionTitle>
          {recievedGiftBoxes.length > rowsToShow * boxCount && (
            <ViewAllButton onClick={handleViewAll}>View All</ViewAllButton>
          )}
        </SectionHeader>

        {isLoading ? (
          <p>Loading...</p>
        ) : (
          <SquareGrid columns={boxCount}>
            {visibleGifts.map((gift) => (
              <SquareBox
                key={gift.id}
                onMouseEnter={() => setHoveredBox(gift.id)}
                onMouseLeave={() => setHoveredBox(null)}
                onClick={() => handleBoxClick(gift)}
              >
                <BoxContent>
                  {/* Check Mark if opened */}
                  <CheckMarkIcon isVisible={gift.opened} />
                  {/* Display Lock Icon if locked, otherwise show BoxImage */}

                  <BoxImage
                    src={getImagePath(gift.box_details.picture)}
                    $isHovered={hoveredBox === gift.id}
                  />
                  {/* Centered Box Name */}
                  <BoxTitle isOpened={gift.opened || !gift.box_details.is_drawable}>
                    {gift.box_details.name}
                  </BoxTitle>
                </BoxContent>
              </SquareBox>

            ))}
          </SquareGrid>
        )}

        <SentGiftsComponent />
        <Footer />
        <Spacer />

      </ContentWrapper>

    </GiftsPageWrapper>
  );
};

export default GiftsPage;
